export const adminBaseUrl = 'https://npapi.learnfromsid.com/admin/admin'
export const traineeBaseUrl = 'https://npapi.learnfromsid.com/trainee/trainee'
// export const traineeBaseUrl = 'http://192.168.216.89:8021/trainee'
// export const adminBaseUrl = 'http://127.0.0.1:8020/admin'

export const adminEndpoints = {
    loginEndpoint: {
        getAdminJwt: '/login'
    },
    overviewEndpoints: {
        GetBatchNameData: '/fetchBatchName',
        GetBatchDashboardData: '/fetchBatchData',
        GetSubBatchDashboardData: '/fetchSubBatchName'


    },
    knowledgeBaseEndpoints: {
        getComponent: '/fetchKnowledgeBase',
        getClass: '/fetchClass',
        deleteFiles: '/deleteFilesKb',
        getUpload: '/uploadFilesKb'
    },
    trainingPlanEndpoints: {
        getTrainingPlan: '/trainingplan',
        getBatch: '/batchtrainingplan',
        getSubBatch: '/batchtrainingplan',
        getClass: '/fetchClass',
        deleteTrainingPlan: '/deletetrainingplan',
        UploadTrainingPLan: '/uploadtrainingplan',
        setEdit: '/edittrainingplan',
        validateUploadfile: '/validateuploadfiletrainingplan'
    },
    userManagement: {
        getUsersDetails: "/fetchUserDetails",
        getInsert: "/insertUsers",
        getAssignBatch: "/assignBatch",
        getEdit: "/editUpdate",
        getEditSubBatch: "/editSubBatch",
        getSubBatch: "/subBatchData",
        getBatch: "/batchData",
        getDomain: "/domainData"
    },
    userProfile: {
        GetUserProfiledata: '/fetchUserData',
        getUserPreviewData: '/fetchUserReviewData'
    },
    OverviewEndpoints: {
        GetBatchNameData: '/fetchBatchName',
        GetBatchDashboardData: '/fetchBatchData',
        GetSubBatchDashboardData: '/fetchSubBatchName'
    },
    LogoutEndpoints: {
        getUpdateJwt: '/updateJwt'
    }
}

export const traineeEndpoints = {
    LoginEndpoints: {
        generateTraineeJwt: "/login"
    },
    TrainingPlanEndpoints: {
        insertUserSessionMapping: "/insertUserSessionMapping",
        fetchTrainingList: "/trainingtopics",
        getBatch: "/batchtrainingplan",
        getSubBatch: "/batchtrainingplan",
        getClass: "/fetchClass",
        deleteTrainingPlan: "/deletetrainingplan",
        UploadTrainingPLan: "/uploadtrainingplan",
        setEdit: "/edittrainingplan",
        validateUploadfile: '/validateuploadfiletrainingplan'
    },
    TrainingTopicEndpoints: {
        fetchTrainingList: '/trainingtopics',
        getClass: '/fetchClass'
    },
    ReviewComponentEndpoints: {
        validateAnswerForReview: '/validateAnswerForReview',
        fetchQuestionForReview: '/fetchQuestionForReview',
        storeReviewDetails: '/storeReviewDetails',
        validatePracticeAnswerForReview : '/validatePracticeAnswerForReview',
        fetchPracticeQuestionForReview: '/fetchPracticeQuestionForReview'
    },
    ChatBotEndpoints: {
        questionTemplate: "/fetchTopicTemplate",
        userInteraction: "/fetchUserInteraction",
        insertErrorData: "/errorLog"

    },
    LogoutEndpoints : {
        getUpdateJwt : '/updateJwt'
    }
}