import React from 'react'
import { useState, useEffect } from 'react';
import { getComponent, getClass } from '../../../Service/AdminApi/knowledgeBaseApi';
import Loader from './loader';
import { Modal, Button } from 'react-bootstrap';
import { EmptyGrid } from './emptyGrid';
import Upload from './upload';
import { DeleteKb } from './deleteKb';
import DeleteTrainingplan from '../TrainingPlan/deleteTrainingplan';
import ToastComponent from './toast';
import { upload } from '@testing-library/user-event/dist/upload';

//PC_GC_04 - PC_GC_101 KbGrid() This function KbGrid is used fetch the grid component 
export const KbGrid = () => {
  const [datacount, setDatacount] = useState(false)
  const [data, setData] = useState([]);
  const [classTable, setClassTable] = useState([]);
  const [search, setSearch] = useState('');
  const [editkey, setEditKey] = useState(0)
  const [filterClassVal, setFilterClassVal] = useState('');
  const [filterFromVal, setFilterFromVal] = useState('');
  const [filterToVal, setFilterToVal] = useState('');
  const [sort, setSort] = useState('');

  const [status, setStatus] = useState('')
  const [sortOrderVal, setSortOrderVal] = useState('DESC');
  const [uploadClick, setUploadClick] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true)
  const [tpCount, setTpCount] = useState(false)
  const [kbId, setkbId] = useState(0)
  const [showFilter, setShowFilter] = useState(false)
  const [cancelSuccess, setCancelSuccess] = useState(false)
  const [count, setCount] = useState(0)

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteid, setdeleteid] = useState(null)
  const [deletesuccess, setdeletesuccess] = useState("")
  const [uploadSuccess, setUploadSuccess] = useState("false")
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  const editObj = {
    editfile: '',
    editclass: '',
    editclassId : '',
    file_size: ''
  }

  const [editProps, seteditprop] = useState(editObj)

  let currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 15000);
  }, [hideToast]);

  // useEffect to fetch data
  useEffect(() => {
    fetchComponent();
  }, [search, sort, sortOrderVal, kbId, deletesuccess, cancelSuccess, count, uploadSuccess]);

  //PC_GC_05 - PC_GC_72 The fetchComponent function asynchronously fetches data based on various filters and sorting options. It constructs a request body using search and filter parameters, then calls the getComponent function with this request body. On success, it updates the component state with the retrieved data, including the total plan count (tpCount), status code, data count, and knowledge base data (KBdata).
  const fetchComponent = async () => {
    try {
      let reqBody = {
        "searchVal": search,
        "filterClass": filterClassVal,
        "filterFrom": filterFromVal,
        "filterTo": filterToVal,
        "sortVal": sort,
        "sortOrder": sortOrderVal
      }
      console.log(reqBody,"reqbody kb");
      let result = await getComponent(reqBody)
      console.log(result);
      //PC_GC_93 - This function loadingFalse is used to set the loader to false
      { result && setLoading(false) }
      console.log(result,"resultKb");
      console.log(result.data.message);
      console.log(result.data, "_________________________________bbjsdjc");
      if (result.data.message == "No Training Plan found") {
        setTpCount(true)
      }
      else if (result.data.message == "No Knowledge Base found") {
        setCount(result.data.KBcount)
        console.log(result.data.KBcount);
        setData(result.data.KBdata)
        setDatacount(true)
      }
      else {
        setData(result.data.KBdata)
        setCount(result.data.KBcount)
        console.log(result.data.KBcount);
      }
    }
    catch (error) {
      setLoading(false)
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
      console.log(error, "ERROR");
    }
  }

  //PC_PC_15-PC_PC_39 The fetchClass function is an asynchronous function designed to fetch class data. It constructs a request body with data set to null and calls the getClass function to retrieve class information. Upon successfully fetching the data, it updates the state variable classTable with the retrieved data
  const fetchClass = async () => {
    try {
      let reqBody = {
        "data": null
      }
      let result = await getClass(reqBody)
      console.log(result.data);
      if (result.data.data == "No Class found") {
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "No Class found !"
        }
        setToastProp(toastData)
        setHideToast(false);
      }
      else {
        setClassTable(result.data.data)
        console.log(result.data.data);
      }
    }
    catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
      console.error(error)
    }
  }


  useEffect(() => {
    fetchClass();
  }, [])

  //PC_GC_96 - PC_GC_98 handleShow() This function can be used to open or display the modal By setting the showModal state to true.

  const handleShow = () => setShowModal(true);
  //PC_GC_99 - PC_GC_101 The handleCancel function is a handler designed to reset various state values and hide a modal.
  const handleCancel = () => {
    setShowModal(false)
    setFilterToVal('');
    setFilterFromVal('');
    setFilterClassVal('');
    setLoading(true)
    setCancelSuccess(true)
  };

  const changeDelete = () => {
    setDeleteClick(false)
    let toastData = {
      toastType: "Positive",
      toastHeaderMessage: "Positive",
      toastBodyMessage: "Knowledge base deleted successfully!"
    }
    setToastProp(toastData)
    setHideToast(false);
  }

  const changeUpload = () => {
    setUploadClick(false)
  }
  const handleDelete = (KbId) => {
    setShowDeleteModal(true);
    setdeleteid(KbId)
  };
  const handleDeleteSuccess = () => {
    setShowDeleteModal(false);
    setdeletesuccess("success")
  };

  // PC_GC_92 - This function loadingTrue is used to set the loader to true
  const loadingTrue = () => {
    setLoading(true)
  }
  //PC_GC_93 - This function loadingFalse is used to set the loader to false
  const loadingFalse = () => {
    setLoading(false)
  }

  const uploadToast = (data) => {
    console.log("rfvekrg3rk", data);
    setToastProp(data);
    setHideToast(false);
  };

  // if (deletesuccess === true) {
  //   let toastData = {
  //     toastType: "Positive",
  //     toastHeaderMessage: "Positive",
  //     toastBodyMessage: "Knowledge base deleted Successfully !"
  //   }
  //   setToastProp(toastData)
  //   setHideToast(false);
  // }

  return (
    <>

      <div className="container-xxxl common-bg">
        {loading ? <Loader /> : (
          <div className="row">
            <div className="col-md-12">
              <div className="common-bg p-4 my-3 px-lg-5 rounded-3">
                <div className="d-flex align-items-center justify-content-between mb-3 pb-3 flex-wrap gap-3">
                  <h5 className="font-20 font-semibold mb-0 primary-color">
                    Knowledge Base ({count || 0})
                  </h5>
                  <div className="d-flex gap-3 small-device-100 flex-wrap">
                    <div className="d-flex gap-3 flex-md-row small-device-100">
                      <div className="input-group input-search" hidden={tpCount}>
                        <input
                          type="search"
                          className="form-control border-end-0 cust-search-input common-bg custom-border font-14 font-regular custom-search"
                          placeholder="Search"
                          onChange={(event) => setSearch(event.target.value.toLowerCase())}
                        />
                        <button
                          className="btn border-start-0 custom-search custom-border"
                          type="button"
                        >
                          <img src="images/search-icon.svg" alt="search" ></img>
                        </button>
                      </div>
                      <button
                        className="btn secondary-outline-btn shadow-sm custom-filter custom-border"
                        type="button"
                        hidden={tpCount}
                        onClick={() => {
                          console.log(showFilter);
                          handleShow()
                        }
                        }
                      >
                        <img src="images/filter-icon.svg" alt="filter-icon" />
                      </button>
                    </div>

                    <Modal show={showModal} onHide={() => setShowModal(false)} hidden={!showModal} className="custom-right-modal" backdrop={false} >
                      <Modal.Header closeButton>
                        <Modal.Title className='font-semibold'>Advanced Filter</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row" >
                          <div className="col-md-6 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Class</label>
                            <div className="dropdown topics-dropdown w-100">
                              <span className="action-drop-down ">
                                <button id="teams"
                                  className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                  type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                  <span className="font-medium font-14 primary-color">{filterClassVal || 'Select'} </span>
                                  <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                </button>
                             <span className="dropdown-menu cust-drpdn-scroll common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                  { classTable?.map((value) => (
                                    <span key={value.class_id} className="d-flex list cursor-pointer p-2 primary-color" onClick={() => setFilterClassVal(value.class_name)}>
                                      {value.class_name}
                                    </span>
                                  ))}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12 mb-2">
                            <label className="font-16 font-semibold custom-header-color primary-color">
                              Date
                            </label>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color primary-color"
                              htmlFor="from">
                              From
                            </label>

                            <input
                              className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                              type="date"
                              id="from"
                              placeholder="MM/DD/YYYY"
                              value={filterFromVal}
                              onChange={(event) => { setFilterFromVal(event.target.value); setFilterToVal("") }}
                            // max={filtertodate}
                            />

                          </div>
                          <div className="col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color primary-color"
                              htmlFor="to"
                            >
                              To
                            </label>
                            <input
                              className="form-control custom-form custom-date dropdown-text-color common-card-theme-bg custom-border"
                              type="date"
                              id="to"
                              placeholder="MM/DD/YYYY"
                              value={filterToVal}
                              min={filterFromVal}
                              onChange={(event) => setFilterToVal(event.target.value)}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center py-3">
                              <button className="btn secondary-btn custom-border text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 custom-filter-button" onClick={() => { handleCancel() }}>
                                Cancel
                              </button>
                              <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center custom-filter-button" onClick={() => {
                                setLoading(true)
                                fetchComponent()
                                setShowModal(false)
                              }} >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>


                    <button className="btn primary-btn text-nowrap  small-device-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={() => {
                      console.log("key is pressed")
                      setUploadClick(true)
                      setEditKey(1)
                    }}>
                      <img src="images/upload-icon.svg" alt="upload-icon" />
                      Upload
                    </button>
                  </div>
                </div>
                <div className="table-responsive theme-table bg-white font-semibold">
                  <table className="table custom-table mb-0 align-middle text-nowrap">

                    <thead className="common-card-theme-bg " style={{ display: tpCount && 'none' }}>
                      <tr className="common-card-theme-bg ">
                        <th className="text-nowrap py-3 px-3 px-lg-4 " >
                          File name
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => {
                                if (sortOrderVal === "ASC") {
                                  setSort("file_name")
                                  setSortOrderVal("DESC")
                                  console.log("clicked", sort);
                                }
                                else {
                                  setSortOrderVal("ASC")
                                  setSort("file_name")
                                  console.log("clicked", sort);
                                }
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap py-3 px-3 px-lg-4" >
                          Class
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => {
                                if (sortOrderVal === "ASC") {
                                  setSort("class_name")
                                  setSortOrderVal("DESC")
                                  console.log("clicked", sort);
                                }
                                else {
                                  setSortOrderVal("ASC")
                                  setSort("class_name")
                                  console.log("clicked", sort);
                                }
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap py-3 px-3 px-lg-4">
                          Uploaded on
                          <span className="ms-1 cursor-pointer">
                            <img
                              src="images/down-arrow-white.svg"
                              alt="sort-arrow-down"
                              className="custom-arrow-color"
                              onClick={() => {
                                if (sortOrderVal === "ASC") {
                                  setSort("uploaded_at")
                                  setSortOrderVal("DESC")
                                  console.log("clicked", sort);
                                }
                                else {
                                  setSortOrderVal("ASC")
                                  setSort("uploaded_at")
                                  console.log("clicked", sort);
                                }
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap text-center py-3 px-3 px-lg-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    {/* : <></>} */}
                    {data ?
                      <tbody>
                        {data?.map((element) => (
                          <tr key={element.id}>
                            <td className="py-3 px-3 px-lg-4 primary-color">
                              {element.file_name}
                            </td>
                            <td key={element.class_id} className="py-3 px-3 px-lg-4 secondary-color">
                              {element.class_name}
                            </td>
                            <td className="py-3 px-3 px-lg-4 secondary-color" >
                              {new Date(element.uploaded_at).toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric'
                              })}

                            </td>
                            <td className="py-3 px-3 px-lg-4">
                              <span className="d-flex justify-content-center gap-3">
                                <button className="common-bg border-0" onClick={() => {
                                  setUploadClick(true)
                                  setEditKey(2)
                                  setkbId(element.knowledge_base_id)
                                  let editObj = {
                                    editfile: element.file_name,
                                    editclassId : element.class_id,
                                    editclass: element.class_name,
                                    filesize: element.file_size
                                  }
                                  seteditprop(editObj)
                                  console.log(editObj, "fgfgjhgh");
                                }}>
                                  <img
                                    className="cursor-pointer"
                                    src="images/table-edit-icon.svg"
                                    alt="Edit"
                                    title="Edit"
                                  />
                                </button>
                                <button className="common-bg border-0" >
                                  <img
                                    className="cursor-pointer"
                                    src="images/table-delete-icon.svg"
                                    alt="Delete"
                                    title="Delete"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete"
                                    onClick={() => {
                                      setkbId(element.knowledge_base_id)
                                      setDeleteClick(true)
                                    }
                                    }
                                  />
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody> :
                      <tbody>
                        <tr>
                          <td colSpan={4} className="primary-color">
                            <div className="p-5 text-center">
                              <img
                                src="images/search-icon-lfs.svg"
                                alt="three-lines-icon"
                                className="pb-4"
                              />
                              <p className="font-14 font-medium primary-color mb-1">
                                No matches found !
                              </p>
                              <p className="font-12 font-medium secondary-color mb-2">
                                It seems we couldn't find any matches for your request.
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>}
                  </table>
                </div>
              </div>
            </div>
            {uploadClick ? <Upload setShowUpload={uploadClick} editKey={editkey} kbId={kbId} displayChange={changeUpload} loadingUpload={loadingFalse} loadChange={loadingTrue} editObj={editProps} uploadToast={uploadToast} onUploadSucess={() => { setUploadSuccess(true) }} ></Upload> : <></>}
          </div>
        )}
      </div>
      {
        <DeleteKb display={deleteClick} kbId={kbId} displayChange={changeDelete} onDeleteSuccess={() => {
          changeDelete()
          setdeletesuccess(true)
        }}></DeleteKb>
      }
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp} />
      }
      {tpCount && <EmptyGrid tpCount={tpCount}></EmptyGrid>}
    </>
  )
}
