import { Configuration, PopupRequest } from "@azure/msal-browser";
// require('dotenv').config();

const clientIdencoded = process.env.REACT_APP_CLIENT_ID

export const msalConfig = {
    auth: {
        clientId: getReportData(clientIdencoded),
        authority: 'https://login.microsoftonline.com/0a6e3df2-2314-4232-97e1-04006154b5bf',
        redirectUri: "https://dev.learnfromsid.com/",
        // redirectUri: "http://localhost:3000/",

    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphEndpoint: "https://graph.microsoft.com/v1.0/users/YOUR_USER_ID/memberOf"

};

function getReportData(es) {
    try {
        let pi = []
        let rs = ""
        let al = ""
        for (let i = es.length - 1; i >= 0; i--) {
            if (!isNaN(es.split("")[i])) {
                al += es.split("")[i]
            }
            else {
                break
            }
        }
        al = al.split('').sort().join('')
        let pl
        pl = es.slice(((es.length - 1) - al), es.length)
        let dp = ""
        for (let i = 0; i < pl.length; i++) {
            if (isNaN(pl.split("")[i])) {
                dp += pl.split("")[i]
            }
            else {
                break;
            }
        }
        dp = atob(dp)
        es = es.split(pl)[0]
        es = atob(es).split(',')
        let ea = es.slice(0, dp)
        pi = es.slice(dp, es.length)
        for (let i = 0; i < ea.length; i++) {
            if (ea[i].length > 3) {
                ea[i] = ea[i].split('').slice(0, 3).join('')
            }
        }
        pi.forEach((e) => {
            ea[e] = ea[e]?.slice(0, 2)
        })
        ea.forEach((e) => {
            rs += String.fromCharCode(e)
        })
        return rs;
    }
    catch(error) 
    {
    console.log(error);
}
}