// App.js
import React, { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../Login/authConfig";
import { getAdminJwt } from "../../Service/AdminApi/adminLoginApi";
import { useNavigate, BrowserRouter } from "react-router-dom";
import ToastComponent from "./ToastComponent";
import { getTraineeJwt } from "../../Service/InternApi/internApi";
import { jwtDecode } from "jwt-decode";
import { setUserSessionMapping } from "../../Service/InternApi/internApi";

function LoginComponent({ setShowAdmin, setLogginStatus }) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [groups, setGroups] = useState([]);
  const [jwtObj, setJwtObj] = useState({});
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 5000);
  }, [hideToast]);


  let navigate = useNavigate();

  const adminGroupId = 'a872fded-dd89-44e8-a2dd-d65de23b140c'
  const traineeGroupId = '26e9ca7a-f059-485c-bfba-6fdda18ec789'

  // This function generates an admin JWT token by calling an API, stores the token and access level in local storage if successful, and displays an error toast if it fails. It's designed to handle authentication for admin users in a web application. 
  const generateAdminJwt = async (userObj) => {
    try {
      let response = await getAdminJwt(userObj)
      console.log(response);
      console.log("token", response.data.token)
      localStorage.setItem('jwt', response.data.token)
      localStorage.setItem('access', userObj.accessLevel)
    }
    catch (error) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong!"
      }
      setToastProp(toastData)
      setHideToast(false);
      console.log(error);

    }
  }

  // This function generates a JWT token for a trainee user by calling an API, then stores the received token and the user's access level in the browser's local storage. It's a simplified version of the admin JWT generation, focused on trainee authentication
  const generateTraineeJwt = async (userObj) => {
    let response = await getTraineeJwt(userObj)
    console.log("token", response.data.token)
    localStorage.setItem('jwt', response.data["token"])
    localStorage.setItem('access', userObj.accessLevel)
    await insertUser()
  }

  const insertUser = async () => {
    try {
      let jwt = localStorage.getItem('jwt');
      if (!jwt) {
        console.log('JWT not found in localStorage');
      }

      const payload = jwtDecode(jwt);
      const userId = payload.userId;
      const userName = payload.userName

      const body1 = {
        "user_id": userId,
        "created_by": userName
      }
      console.log(body1, "body1");

      let response = await setUserSessionMapping(body1)

      return response
    }
    catch (e) {
      console.log('Error fetching class:', e);
    }
  }

  // This function checks the user's access level by examining their group membership. It sets the appropriate access level (Admin or Trainee), generates the corresponding JWT, and navigates to the relevant page. If the user doesn't belong to either group, it sets a default login status. The function includes error handling to display a toast message if something goes wrong. 
  const checkUserAccess = async (groupArr, userObj) => {
    console.log("function is called")
    console.log(groupArr)
    try {
      if (groupArr.indexOf(adminGroupId) != -1) {
        console.log("user is admmin")
        setShowAdmin(true)
        setLogginStatus(true)
        userObj['accessLevel'] = 'Admin'
        const response = await generateAdminJwt(userObj)
        navigate("/adminlanding")
      }
      
      else if (groupArr.indexOf(traineeGroupId) != -1) {
        setShowAdmin(false)
        setLogginStatus(true)
        userObj['accessLevel'] = 'Trainee'
        const response = await generateTraineeJwt(userObj)
        navigate("/Trainee")
      }
      else {
        setShowAdmin(null)
        setLogginStatus(true)
      }
    }
    catch (error) {
      console.log(error);
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  }

  // This function retrieves a user's group memberships from Microsoft Graph API using an access token. It processes the response to extract group IDs, updates the component state with these IDs, and then calls checkUserAccess to determine the user's access level. The function includes error handling to display a toast message if token acquisition or API request fails. 
  const getGroups = (userId, userObj) => {
    const request = {
      ...loginRequest,
      account: account
    };

    instance.acquireTokenSilent(request).then(tokenResponse => {
      fetch(`https://graph.microsoft.com/v1.0/users/${userId}/memberOf`, {
        headers: {
          Authorization: `Bearer ${tokenResponse.accessToken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data, "response")
          let tempUserIdArr = []
          data.value.forEach((element) => {
            tempUserIdArr.push(element.id)
          })
          console.log(tempUserIdArr)
          setGroups(() => (tempUserIdArr))
          return tempUserIdArr
        })
        .then(data => {
          checkUserAccess(data, userObj)
        })
        .catch(err => console.error(err));
    }).catch(err => {
      console.error(err);
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
    });
  };

  // This function initiates the user login process using a popup window. Upon successful login, it sets the active account, updates the JWT object with user details, and calls getGroups to fetch the user's group memberships. If login fails, it displays an error toast message. The function integrates authentication, user data management, and error handling in a single flow. 
  const userLogin = () => {
    instance.loginPopup(loginRequest)
      .then(response => {
        console.log("Login was successful", response.account);
        instance.setActiveAccount(response.account);
        setJwtObj({
          userName: response.account.name,
          userId: response.account.localAccountId,
          userAccess: ''
        })
        getGroups(response.account.localAccountId, {
          userName: response.account.name,
          userId: response.account.localAccountId
        });

      })
      .catch(error => {
        console.error("Login failed", error);
        let toastData = {
          toastType: "Negative",
          toastHeaderMessage: "Negative",
          toastBodyMessage: "Something Went Wrong !"
        }
        setToastProp(toastData)
        setHideToast(false);
      });
  };

  return (
    <div className="login-background-image d-flex align-items-center">
      <div className="text-center w-100">
        <div className="row m-0">
          <div className="d-flex justify-content-center">
            <div className="col-md-auto login-box common-card-theme-bg custom-border">
              <div className="d-flex flex-column px-5 py-4">
                <span className="pb-3">
                  <img
                    className="chart-logo"
                    src="images/Logo.svg"
                    alt="LFS-logo"
                  />
                </span>
                <h3 className="font-20 primary-color fw-bold">Welcome to LFS</h3>
                <p className="font-12 secondary-color">
                  Your training companion Sid, here to answer and review!
                </p>
              </div>
              <div className="px-5 py-4 login-button-bg">
                <button className="btn primary-btn w-100" onClick={() => {
                  userLogin()
                }}>Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hideToast ? <></> :
        <ToastComponent name={toastProp} />
      }
    </div>
  );
}

export default LoginComponent;