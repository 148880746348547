import base64 from 'base-64'; // Make sure to import base-64 library if not already included

function getRandomCharCode() {
    const ranges = [
      [48, 57], // Numbers 0-9
      [65, 90], // Uppercase A-Z
      [97, 122] // Lowercase a-z
    ];
  
    // Select a range randomly and then select a code within that range
    const [min, max] = ranges[Math.floor(Math.random() * ranges.length)];
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  function randomString(length = 10) {
    return Array.from({ length }, () => String.fromCharCode(getRandomCharCode())).join('');
  }
  
  function base64Encode(str) {
    return btoa(unescape(encodeURIComponent(str)));
  }
  
  function encrypt(data) {
    try {
      console.log(data, "data");
      const randomStr = randomString(10);
      const encodedData = base64Encode(JSON.stringify(data));
      const encryptedData = randomStr + encodedData;
      return {data:encryptedData} ; // Return encrypted data
    } catch (error) {
      console.log(error, "encryption error");
      return { error: "Encryption failed", status: 500 }; // Return error message
    }
  }
  
  function base64Decode(str) {
    return decodeURIComponent(escape(atob(str)));
  }
  
  function decrypt(encryptedData) {
    try {
      // The first 10 characters are the random string we added during encryption
      console.log(encryptedData);
      const base64Data = encryptedData.slice(10);
      // console.log(base64Data,"base64Data");
      const decodedData = base64Decode(base64Data);
      console.log(decodedData,"decodedData");
      const parsedData = JSON.parse(decodedData);
      console.log(parsedData,"decrypteddd")
      return parsedData ; // Return decrypted data
    } catch (error) {
      console.log(error, "decryption error");
      return { error: "Decryption failed", status: 500 }; // Return error message
    }
  }

export { encrypt, decrypt };