import React, { useState, useRef, useEffect, useCallback } from 'react';
import { validateAnswerForReview, fetchQuestionForReview, storeReviewDetails } from '../../../Service/InternApi/internApi';
import Loader from './Loader';
import { fileToBase64, blobToAudioBase64, readFileAsBase64 } from '../../../Helper/handleFunction';
import { useNavigate, useLocation } from "react-router-dom";
import { insertErrorData } from '../../../Service/InternApi/internApi';
import NavBarComponent from '../NavBar/NavBarComponent';
import { jwtDecode } from 'jwt-decode';
import ToastComponent from '../Toast/ToasatComponent';
import LiveStreamVideo from './LiveStream';

/*
PC_LFS_RC_05 to PC_LFS_RC_325
ReviewComponent() function is used to take review to the trainee's
return review component
*/
const ReviewComponent = () => {

    const location = useLocation();
    const { state } = location;


    const tempState = localStorage.getItem('jwt')
    const [state1, setState] = useState(jwtDecode(tempState))
    const userId = state1.userId
    const userName = state1.userName

    const training_session_id = state ? state.training_session_id : null;
    // console.log(training_session_id, "trs");
    const training_session_name = state ? state.training_session_name : null;

    const [reviewContent, setReviewContent] = useState({
        userId: userId,
        questionKey: '',
        answerKey: '',
        question: '',
        reviewQuestionList: '',
        attitudeQuestionList: '',
        errorCount: '',
        questionAnswerList: [],
        fileName: [],
        filesize: [],
        fileBlobUrl: [],
        fileBase64Data: [],
        topic: training_session_name,
        training_session_id: training_session_id
    })

    const [loading, setloading] = useState(false)

    const [screenType, setScreenType] = useState(1)

    const [audioButton, setAudioButton] = useState(false)
    const [inputButton, setInputButton] = useState(false)
    const [currentFileStatus, setCurrentFileStatus] = useState([])
    const [updateReviewMessage, setUpdateReviewMessage] = useState([])

    const [startVisual, setStartVisual] = useState(false);
    const [testStarted, setTestStarted] = useState(false);
    const [question, setQuestion] = useState('');
    const [cheatCount, setCheatCount] = useState(0);
    const [fileFlag, setFileFlag] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [reviewMessage, setReviewMessage] = useState([]);
    const [liveTranscript, setLiveTranscript] = useState('');
    const [result, setResult] = useState(null);
    const [fileName, setFileName] = useState([]);
    const [fileBlobUrl, setFileBlobUrl] = useState([]);
    const [questionBase64Ans, setQuestionBase64Ans] = useState([]);
    const [answerText, setAnswerText] = useState('');
    const [fileUpload, setFileUpload] = useState(false);
    const [fileUploadBt, setFileUploadBt] = useState(false);
    const [fileBase64Data, setFileBase64Data] = useState([]);
    const [errorFile, setErrorFiles] = useState(false);
    const [allFiles, setAllFiles] = useState([]);
    const [fileStatus, setFileStatus] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const [voicess, setVoice] = useState()
    const [inputFieldStatus, setInputFieldStatus] = useState(false)

    const [reviewPop, setReviewPop] = useState(false)

    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const analyserRef = useRef(null);
    const freqsRef = useRef(null);
    const recognitionRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const transcriptRef = useRef([]);
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);

    const innerChatContainerRef = useRef(null)

    let routing = useNavigate()
    // routing("/review-ended") //need to set that in the final button test ended button

    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState(toastData);

    // camera enabled 
    const [isRecording, setIsRecording] = useState(false);
    const [recordedVideo, setRecordedVideo] = useState(null);
    const webcamStream = useRef(null);
    const screenStream = useRef(null);
    const screenRecorderRef = useRef(null);
    const webcamRecorderRef = useRef(null);

    const recordedChunksRef = useRef([]);
    const webcamVideoRef = useRef(null);
    const audioStream = useRef(null);
    const videoRef = useRef(null);
    const audioContextRef = useRef(null);
    const videoBase64Ref = useRef("");
    const screenBase64Ref = useRef("")

    const [isEntireScreenShared, setIsEntireScreenShared] = useState(false);
    const [webcamActive, setWebcamActive] = useState(false);

    const [videoBase64, setVideobase64] = useState([]);
    const [screenBase64String, setScreenBase64String] = useState([])

    const [duration, setDuration] = useState(0);
    const [durationIntervalId, setDurationIntervalId] = useState(null);

    const [isScreenReady, setIsScreenReady] = useState(false);
    const [webcamStreamrec, setWebcamStreamrec] = useState(null);

    const [buttonState, setButtonState] = useState('record'); // 'record' or 'test'
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 15000);
    }, [hideToast]);


    useEffect(() => {
        if (startVisual) {
            startVisualization();
        }
    }, [startVisual]);

    useEffect(() => {
        setVoiceForSpeech("")
    }, []);

    useEffect(() => {
        updateReviewMessages();
    }, [reviewMessage]);

    useEffect(() => {
        if (testStarted) {
            document.addEventListener('visibilitychange', checkVisibilityChange);
            document.addEventListener('fullscreenchange', checkFullscreenChange);
            document.addEventListener('webkitfullscreenchange', checkFullscreenChange);
        }
        return () => {
            if (testStarted) {
                document.removeEventListener('visibilitychange', checkVisibilityChange);
                document.removeEventListener('fullscreenchange', checkFullscreenChange);
                document.removeEventListener('webkitfullscreenchange', checkFullscreenChange);
            }
        };
    }, [testStarted]);

    useEffect(() => {
        if (reviewContent.question !== "" && cheatCount !== 3 && testStarted === true) {
            setTestStarted(true);

            botQuestion(reviewContent.question);
        }
    }, [testStarted, question]);

    useEffect(() => {
        scrollToBottom();
    }, [updateReviewMessage]);


    // function scrollToBottom() {
    //     if (innerChatContainerRef.current) {
    //         innerChatContainerRef.current.scrollTop =
    //             innerChatContainerRef.current.scrollHeight;
    //         innerChatContainerRef.current.scrollTo({
    //             top: innerChatContainerRef.current.scrollHeight,
    //             behavior: "smooth",
    //         });
    //     }
    // }


    const transcriptContainerRef = useRef(null);

    const scrollToBottom = () => {
        if (transcriptContainerRef.current) {
            transcriptContainerRef.current.scrollTop = transcriptContainerRef.current.scrollHeight;
        }
    };



    useEffect(() => {
        // // console.log("cheat count: ", cheatCount, fileFlag, testStarted, "-----------------------", (question?.length === 0 || cheatCount === 3) && fileFlag == false && testStarted == true)
        if ((question?.length === 0 || cheatCount === 3) && fileFlag == false && testStarted == true) {
            // // console.log("going to end the test")
            setTestStarted(false);
            stopTest();
            // // console.log("test ended");
        }
    }, [reviewContent, cheatCount]);

    useEffect(() => {
        if (webcamVideoRef.current && webcamStream.current) {
            webcamVideoRef.current.srcObject = webcamStream.current;
            showReviewScreen(webcamVideoRef)
        }
    }, []);


    /*fs
    PC_LFS_RC_83 to PC_LFS_RC_88
    setVoiceForSpeech() function is used to set the female voice in the state variable 
    params text
    */
    const setVoiceForSpeech = (text) => {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel();
        }

        const awaitVoices = new Promise(resolve =>
            window.speechSynthesis.onvoiceschanged = resolve)
            .then(() => {
                const synth = window.speechSynthesis;
                const voices = synth.getVoices();

                const utterance = new SpeechSynthesisUtterance(text);

                // Set language to English
                utterance.lang = 'en-US';

                // Find an English voice
                const englishVoice = voices.find(voice => voice.lang.startsWith('en-'));

                if (englishVoice) {
                    utterance.voice = englishVoice;
                } else {
                    console.warn('No English voice found. Using default voice.');
                }

                // Speak the utterance
                synth.speak(utterance);
            });
    };

    /*
    PC_LFS_RC_11 to PC_LFS_RC_25
    startVisualization() function is used to show the visualization for the speech
    return the visualization for the speech
    */
    function startVisualization() {
        try {
            const canvas = canvasRef.current;
            const WIDTH = 1000;
            const HEIGHT = 400;

            const opts = {
                smoothing: 0.6,
                fft: 8,
                minDecibels: -60,
                scale: 0.2,
                glow: 10,
                color1: [203, 36, 128],
                color2: [41, 200, 192],
                color3: [24, 137, 218],
                fillOpacity: 0.6,
                lineWidth: 1,
                blend: 'screen',
                shift: 50,
                width: 60,
                amp: 1,
            };

            contextRef.current = canvas.getContext('2d');
            const shuffle = [1, 3, 0, 4, 2];

            function freq(channel, i) {
                const band = 2 * channel + shuffle[i] * 6;
                return freqsRef.current[band];
            }


            function scale(i) {
                const x = Math.abs(2 - i);
                const s = 3 - x;
                return (s / 3) * opts.amp;
            }

            function path(channel) {
                const color = opts[`color${channel + 1}`].map(Math.floor);
                const ctx = contextRef.current;
                const m = HEIGHT / 2;
                const offset = (WIDTH - 15 * opts.width) / 2;
                const x = Array.from({ length: 15 }, (_, i) => offset + channel * opts.shift + i * opts.width);
                const y = Array.from({ length: 5 }, (_, i) => Math.max(0, m - scale(i) * freq(channel, i)));
                const h = 2 * m;

                ctx.fillStyle = `rgba(${color}, ${opts.fillOpacity})`;
                ctx.strokeStyle = ctx.shadowColor = `rgb(${color})`;
                ctx.lineWidth = opts.lineWidth;
                ctx.shadowBlur = opts.glow;
                ctx.globalCompositeOperation = opts.blend;

                ctx.beginPath();
                ctx.moveTo(0, m);
                ctx.lineTo(x[0], m + 1);

                ctx.bezierCurveTo(x[1], m + 1, x[2], y[0], x[3], y[0]);
                ctx.bezierCurveTo(x[4], y[0], x[4], y[1], x[5], y[1]);
                ctx.bezierCurveTo(x[6], y[1], x[6], y[2], x[7], y[2]);
                ctx.bezierCurveTo(x[8], y[2], x[8], y[3], x[9], y[3]);
                ctx.bezierCurveTo(x[10], y[3], x[10], y[4], x[11], y[4]);

                ctx.bezierCurveTo(x[12], y[4], x[12], m, x[13], m);

                ctx.lineTo(1000, m + 1);
                ctx.lineTo(x[13], m - 1);

                ctx.bezierCurveTo(x[12], m, x[12], h - y[4], x[11], h - y[4]);
                ctx.bezierCurveTo(x[10], h - y[4], x[10], h - y[3], x[9], h - y[3]);
                ctx.bezierCurveTo(x[8], h - y[3], x[8], h - y[2], x[7], h - y[2]);
                ctx.bezierCurveTo(x[6], h - y[2], x[6], h - y[1], x[5], h - y[1]);
                ctx.bezierCurveTo(x[4], h - y[1], x[4], h - y[0], x[3], h - y[0]);
                ctx.bezierCurveTo(x[2], h - y[0], x[1], m, x[0], m);

                ctx.lineTo(0, m);

                ctx.fill();
                ctx.stroke();
            }

            function visualize() {
                const ctx = contextRef.current;
                const { fft, smoothing, minDecibels } = opts;

                analyserRef.current.smoothingTimeConstant = smoothing;
                analyserRef.current.fftSize = Math.pow(2, fft);
                analyserRef.current.minDecibels = minDecibels;
                analyserRef.current.maxDecibels = 0;
                analyserRef.current.getByteFrequencyData(freqsRef.current);

                canvas.width = WIDTH;
                canvas.height = HEIGHT;

                path(0);
                path(1);
                path(2);

                requestAnimationFrame(visualize);
            }

            function onStream(stream) {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyser = audioContext.createAnalyser();
                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);
                contextRef.current = canvas.getContext('2d');
                analyserRef.current = analyser;
                freqsRef.current = new Uint8Array(analyser.frequencyBinCount);

                visualize();
            }

            function onStreamError(e) {
                console.error(e);
            }

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(onStream)
                .catch(onStreamError);
        }
        catch (e) {
            const data = {
                error_type: "review component",
                error_source: "startVisualization()",
                error_description: e.toString()
            };
            // // console.log("stop test(catch):", data)
            // insertErrorData(data);
        }


    }

    /*
    PC_LFS_RC_30 to PC_LFS_RC_32
    checkVisibilityChange() function is used to monitor the visibility changes while attending the review
    set the cheatCount increment by 1
    */
    const checkVisibilityChange = () => {
        if (document.hidden) {
            // // console.log('User navigated away or went out of focus.');
            setCheatCount(prevCheat => prevCheat + 1);
        }
    };

    /*
    PC_LFS_RC_33 to PC_LFS_RC_35
    checkFullscreenChange() function is used to monitor the fullscreen changes while attending the review
    set the cheatCount increment by 1
    */
    const checkFullscreenChange = () => {
        if (!document.fullscreenElement && !document.webkitFullscreenElement) {
            // // console.log('User exited full-screen mode.');
            setCheatCount(prevCheat => prevCheat + 1);
        }
    };

    /*
    PC_LFS_RC_143 to PC_LFS_RC_152
    enterFullScreen() function is used to enter the fullscreen mode 
    */
    const enterFullScreen = async () => {
        try {
            console.log("Entering full-screen mode...");
            const element = document.documentElement;

            // Check if the browser supports fullscreen mode
            if (element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen || element.msRequestFullscreen) {
                // Check if fullscreen is already active
                if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
                    // If not active, request fullscreen
                    console.log("enteringggggggggg");
                    const requestFullScreen = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen || element.msRequestFullscreen;
                    await requestFullScreen.call(element);
                } else {
                    // Fullscreen is already active
                    console.log('Fullscreen is already active.');
                }
            } else {
                // Browser doesn't support fullscreen mode
                console.log('Fullscreen mode is not supported in this browser.');
            }
        } catch (error) {
            const data = {
                error_type: "review component",
                error_source: "enterFullScreen function",
                error_description: error.toString()
            };
        }
    };

    /*
    PC_LFS_RC_316 to PC_LFS_RC_319
    exitFullScreen() function is used to exit the fullscreen mode 
    */
    const exitFullScreen = () => {
        // // console.log("exit fullscreen function get triggered");
        document.body.classList.remove('modal-open');

        // Check if the document is currently in full-screen mode
        if (document.fullscreenElement || document.webkitFullscreenElement) {
            // If in full-screen, exit full-screen mode
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        } else {
            // If not in full-screen, do nothing
            // // console.log('Not in full-screen mode.');
        }
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    /*
    PC_LFS_RC_126 to PC_LFS_RC_152
    startTest() function is used to start the review test, store the start time and call the fetchQuestionForReview() function to get and set the values
    */
    const startTest = async () => {
        try {
            // await screenRecording()
            try {
                console.log("Entering full-screen mode...");
                const element = document.documentElement;

                // Check if the browser supports fullscreen mode
                if (element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen || element.msRequestFullscreen) {
                    // Check if fullscreen is already active
                    if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
                        // If not active, request fullscreen
                        console.log("enteringggggggggg");
                        const requestFullScreen = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen || element.msRequestFullscreen;
                        await requestFullScreen.call(element);

                    } else {
                        // Fullscreen is already active
                        console.log('Fullscreen is already active.');
                    }
                } else {
                    // Browser doesn't support fullscreen mode
                    console.log('Fullscreen mode is not supported in this browser.');
                }
            } catch (error) {
                const data = {
                    error_type: "review component",
                    error_source: "enterFullScreen function",
                    error_description: error.toString()
                };
            }

            setloading(true)
            setStartTime(new Date());

            // Declare the variable data with key (topic_session_id) value (topic_session_id)
            const data = { training_session_id: training_session_id };

            // Call fetchQuestionForReview() by passing data as parameter
            var result1 = await fetchQuestionForReview(data)
            if (result1.data.data == "No KB found") {
                setloading(false);
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: "No knowledge base uploaded!"
                };
                setToastProp(toastData);
                setHideToast(false);
                // Delay the redirection to allow the toast message to be shown
                setTimeout(() => {
                    routing('/Trainee', { state: { errorMessage: toastData } });
                }, 3000);
            }
            if (result1.status == 200) {

                var result = result1.data
                setResult(result);
                setQuestion(result["nextQuestion"]);
                setStartVisual(true);
                setloading(false)
                setScreenType(2)
                setTestStarted(true);
                setIsDisabled(true);
                setReviewContent({ ...reviewContent, questionKey: result["responseKey"], question: result["nextQuestion"], reviewQuestionList: result["reviewQuestionList"], attitudeQuestionList: result["attitudeQuestionList"], errorCount: result["errorCount"] })

            }
            else {
                setloading(false)
                exitFullScreen()
                setloading(false)
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: "Something Went Wrong  !"
                }
                setToastProp(toastData)
                setHideToast(false);
                routing("/trainee")
            }
        }
        catch (e) {
            exitFullScreen()
            setloading(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong  !"
            }
            setToastProp(toastData)
            setHideToast(false);
            const data = {
                error_type: "review component",
                error_source: "startTest function",
                error_description: e.toString()
            };
            // // console.log("stop test(catch):", data)
            // insertErrorData(data);
        }


    };

    // SE_LFS_RC_2.27 - SE_LFS_RC_2.50 This function sets up and initiates screen recording with audio and webcam input. It sequentially acquires screen, audio, and webcam streams, merges them, and sets up recorders for both screen and webcam. The function also manages UI state, enabling a button after a delay and setting the recording status. It includes error handling to manage potential issues during the setup process. 
    const screenRecording = async () => {
        try {
            // Step 1: Get display media
            screenStream.current = await getDisplayMedia();

            // Step 2: Validate screen sharing
            validateScreenSharing(screenStream.current);

            // Step 3: Get audio stream
            audioStream.current = await getAudioStream();

            // Step 4: Get webcam stream
            webcamStream.current = await getWebcamStream();

            // Step 5: Set up audio context and merge streams
            const combinedStream = setupAudioAndMergeStreams(screenStream.current, audioStream.current, webcamStream.current);

            // Step 6: Set up screen recorder
            setupScreenRecorder(combinedStream);

            // Step 7: Set up webcam recorder
            setupWebcamRecorder();

            setIsRecording(true);
            console.log("Recording setup complete");

            setTimeout(() => {
                setButtonState('test');
                setIsDisabled(false);
            }, 3000);

            // Return a promise that resolves after a delay
            return new Promise((resolve) => setTimeout(resolve, 5000));
        } catch (error) {
            console.error('Error in screenRecording:', error);
            handleRecordingError(error);
            // throw error;
        }
    };

    // SE_LFS_RC_2.45 This function uses the browser's MediaDevices API to request screen sharing from the user. It specifically asks for video content from a monitor display and includes audio. The function returns a Promise that resolves with a MediaStream object containing the requested screen content and audio. It's a crucial step in setting up screen recording functionality. 
    const getDisplayMedia = async () => {
        return await navigator.mediaDevices.getDisplayMedia({
            video: { displaySurface: "monitor" },
            audio: true
        });
    };

    // SE_LFS_RC_2.36 - SE_LFS_RC_2.37 This function validates the screen sharing setup. It checks if the shared content is from a monitor and if system audio is included. If either condition is not met, it throws an error with a specific message. If both conditions are satisfied, it sets a state variable indicating that the entire screen is shared. This ensures that the recording captures the intended content and audio. 
    const validateScreenSharing = (stream) => {
        const videoTrack = stream.getVideoTracks()[0];
        const systemAudioTrack = stream.getAudioTracks()[0];

        if (!videoTrack) {
            console.log("No video track found in the stream.");
        }

        if (videoTrack.getSettings().displaySurface !== 'monitor') {
            console.log("Please share your entire screen!");
        }

        if (!systemAudioTrack) {
            console.log("Please include system audio in your screen share!");
        }

        setIsEntireScreenShared(true);
    };

    // SE_LFS_RC_2.44 This function attempts to access the user's microphone to obtain an audio stream. It uses the getUserMedia method from the MediaDevices API, requesting audio input. If successful, it returns the audio stream. If there's an error (e.g., user denies permission or no microphone is available), it logs the error and returns null. This function is crucial for capturing the user's voice in recordings. 
    const getAudioStream = async () => {
        try {
            return await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (error) {
            console.log('Error accessing microphone:', error);
            return null;
        }
    };

    // SE_LFS_RC_2.33 - SE_LFS_RC_2.35 This function attempts to access the user's webcam to obtain a video stream. It uses getUserMedia to request video input (without audio). If successful, it logs the stream, updates state variables to indicate an active webcam, and returns the stream. If there's an error (e.g., user denies permission or no webcam available), it logs the error and returns null. This function is essential for including webcam footage in recordings. 
    const getWebcamStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            console.log("Webcam stream obtained:", stream);
            setWebcamStreamrec(stream);
            setWebcamActive(true);
            return stream;
        } catch (error) {
            console.error('Error accessing webcam:', error);
            return null;
        }
    };

    // SE_LFS_RC_2.38 - SE_LFS_RC_2.42 This function sets up audio processing and merges multiple media streams. It creates an AudioContext, combines system audio and microphone input with separate gain controls, merges these audio streams, and then combines the processed audio with video tracks from screen and webcam streams. The result is a single MediaStream containing screen video, processed audio, and webcam video (if available). This complex audio setup allows for fine-tuned control over different audio sources in the final recording
    const setupAudioAndMergeStreams = (screenStream, audioStream, webcamStream) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = audioContext;

        const systemAudioTrack = screenStream.getAudioTracks()[0];
        const systemAudioSource = audioContext.createMediaStreamSource(new MediaStream([systemAudioTrack]));
        const micAudioSource = audioContext.createMediaStreamSource(audioStream);

        const systemGain = audioContext.createGain();
        const micGain = audioContext.createGain();

        systemAudioSource.connect(systemGain);
        micAudioSource.connect(micGain);

        const merger = audioContext.createChannelMerger(2);
        systemGain.connect(merger, 0, 0);
        micGain.connect(merger, 0, 1);

        const dest = audioContext.createMediaStreamDestination();
        merger.connect(dest);

        return new MediaStream([
            ...screenStream.getVideoTracks(),
            ...dest.stream.getAudioTracks(),
            ...(webcamStream ? webcamStream.getVideoTracks() : [])
        ]);
    };

    // SE_LFS_RC_2.50-SE_LFS_RC_2.51 This function sets up the screen recorder using the MediaRecorder API. It initializes a new MediaRecorder with the combined stream (screen, audio, and webcam) and specifies MP4 as the output format. It sets up event handlers for data availability and recording stop. When data is available, it's added to a chunks array. When recording stops, it creates a Blob from the chunks, converts it to a base64 string, and stores it in a ref. Finally, it starts the recording. This function is crucial for capturing and processing the screen recording. 
    const setupScreenRecorder = (combinedStream) => {

        const options = { mimeType: "video/mp4" };

        screenRecorderRef.current = new MediaRecorder(combinedStream, options);

        screenRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                recordedChunksRef.current.push(event.data);
            }
        };

        screenRecorderRef.current.onstop = async () => {
            const screenBlob = new Blob(recordedChunksRef.current, { type: 'video/mp4' });
            const screenBase64 = await readFileAsBase64(screenBlob);
            screenBase64Ref.current = screenBase64.base64Data;
            recordedChunksRef.current = [];
        };

        screenRecorderRef.current.start();
    };

    //SE_LFS_RC_2.48 - SE_LFS_RC_2.50 This function sets up the webcam recorder. It first checks if a webcam stream is available. If so, it starts an interval to update the recording duration every second. It then initializes a MediaRecorder for the webcam stream. Event handlers are set up to collect data chunks and process them when recording stops. On stop, it creates a Blob from the recorded chunks, converts it to a base64 string, and stores it in a ref. The function starts the webcam recording at the end. This setup allows for separate recording of webcam footage alongside the screen recording. 
    const setupWebcamRecorder = () => {
        if (!webcamStream.current) return;

        const intervalId = setInterval(() => {
            setDuration(prev => prev + 1);
        }, 1000);

        // Store interval ID to clear it later
        setDurationIntervalId(intervalId);

        webcamRecorderRef.current = new MediaRecorder(webcamStream.current);

        webcamRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                recordedChunksRef.current.push(event.data);
            }
        };

        webcamRecorderRef.current.onstop = async () => {
            const webcamBlob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
            const webcamBase64 = await readFileAsBase64(webcamBlob);
            videoBase64Ref.current = webcamBase64.base64Data;
        };

        webcamRecorderRef.current.start();
    };

    const handleRecordingError = (error) => {
        // Stop any active streams
        if (screenStream.current) {
            screenStream.current.getTracks().forEach(track => track.stop());
        }
        if (audioStream.current) {
            audioStream.current.getTracks().forEach(track => track.stop());
        }
        if (webcamStream.current) {
            webcamStream.current.getTracks().forEach(track => track.stop());
        }

        // Show error toast
        let toastData = {
            toastType: "Negative",
            toastHeaderMessage: "Recording Error",
            toastBodyMessage: error.message || "Failed to start recording. Please try again."
        };
        setToastProp(toastData);
        setHideToast(false);

        // Optionally reload the page
        window.location.reload();
    };

    // SE_LFS_RC_1.51 - SE_LFS_RC_1.59 This function stops the screen recording process. It checks if a recorder is active and recording is in progress. If so, it stops the recorder, resets state variables, and stops all tracks in the screen, audio, and webcam streams. It also clears the webcam video source and closes the AudioContext. This function is crucial for properly ending the recording session, releasing resources, and preparing for potential future recordings. The useCallback hook ensures the function is memoized and only recreated when isRecording changes. 
    const stopScreenRecording = useCallback(() => {
        if (screenRecorderRef.current && isRecording) {
            screenRecorderRef.current.stop();
            setIsEntireScreenShared(false);
            setIsRecording(false);

            if (screenStream.current) {
                screenStream.current.getTracks().forEach(track => track.stop());
            }
            if (audioStream.current) {
                audioStream.current.getTracks().forEach(track => track.stop());
            }
            if (webcamStream.current) {
                webcamStream.current.getTracks().forEach(track => track.stop());
            }

            if (webcamVideoRef.current) {
                webcamVideoRef.current.srcObject = null;
            }

            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                audioContextRef.current.close().then(() => {
                    console.log('AudioContext closed successfully');
                }).catch((error) => {
                    console.error('Error closing AudioContext:', error);
                });
            }
        }
    }, [isRecording]);

    // This function stops the recording timer. It clears the interval that was updating the duration, resets the interval ID state, and then formats the final duration. The formatted duration is logged and returned. This function is important for accurately tracking and reporting the length of the recording session, providing useful metadata for the recorded content. 
    const stopTimer = () => {
        if (durationIntervalId) {
            clearInterval(durationIntervalId);
            setDurationIntervalId(null);

            const formattedDuration = formatDuration(duration);
            console.log("Recorded duration:", formattedDuration);
            return formattedDuration

        }
    };

    /*
    PC_LFS_RC_57 to PC_LFS_RC_80
    stopTest() function is used to stop the review test, store the review details by call the storeReviewDetails() function
    */
    const stopTest = async () => {
        try {
            stopScreenRecording()
            stopRecording()
            setloading(true)
            let finalDuration = stopTimer()
            console.log(finalDuration);


            // Set testStarted state variable to false

            // Declare and initialize malpractice variable as false
            let malpractice = false;

            // Check if cheatCount equals 3
            if (cheatCount === 3) {
                // Set malpractice variable to true
                malpractice = true;
            }

            await new Promise(resolve => setTimeout(resolve, 1000));
            console.log(screenBase64Ref.current, "screenBase64Ref.current");
            console.log(videoBase64Ref.current, "videoBase64Ref.current");

            const data = {
                userId: userId,
                training_session_id: training_session_id,
                reviewMessage: reviewMessage,
                questionBase64Ans: questionBase64Ans,
                errorCount: reviewContent.errorCount,
                questionAnswerList: reviewContent.questionAnswerList,
                fileName: reviewContent.fileName,
                filesize: reviewContent.filesize,
                fileBlobUrl: reviewContent.fileBlobUrl,
                screenBase64: screenBase64Ref.current,
                // videoBase64: videoBase64Ref.current,
                duration: finalDuration,
                malpractice: malpractice,
                topic: reviewContent.topic,
                created_by: userName
            };
            console.log(finalDuration);
            console.log(data, "requestbody");
            const result1 = await storeReviewDetails(data);
            // // console.log("stop test(requestBody): ", data, "---------------------------", result1)
            setloading(false)

            document.body.classList.add('modal-open');
            setReviewPop(true)

            console.log(screenBase64String, "///////////////////")


            // // console.log("test end bye bye----------------------------")


        } catch (e) {

            setloading(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong  !"
            }
            setToastProp(toastData)
            setHideToast(false);
            // Handle any errors that occur
            const data = {
                error_type: "review component",
                error_source: "stopTest",
                error_description: e.toString()
            };
            // // console.log("stop test(catch):", data)
            // insertErrorData(data);
        }
    };

    /*
    PC_LFS_RC_41 to PC_LFS_RC_53
    botQuestion() function is used to speek the question using the speechSynthesis
    return the question as speech/voice
    */
    const botQuestion = async (text) => {
        try {
            if ('speechSynthesis' in window) {
                // // console.log("bot question function get triggered.");

                const utterance = new SpeechSynthesisUtterance(text);

                utterance.voice = voicess

                setStartVisual(true);

                // Set up event listener for when speech ends
                utterance.onend = () => {
                    // Set startVisual state variable to false
                    setStartVisual(false);
                    setInputFieldStatus(true)

                    // Calculate time difference and set it to reviewMessage state variable
                    var endTime = new Date();
                    var timeDiffMilliseconds = endTime - startTime;
                    var timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000); // Convert milliseconds to seconds and round down
                    var minutes = Math.floor(timeDiffSeconds / 60); // Calculate the minutes
                    var seconds = timeDiffSeconds % 60; // Calculate the remaining seconds

                    var time = minutes.toString().padStart(2, '0') + '.' + seconds.toString().padStart(2, '0');
                    const message = { "question": text, "time": time };
                    setReviewMessage(reviewMessage.concat(message));
                    console.log(text)
                    console.log(questionBase64Ans.concat(text))
                    setQuestionBase64Ans((prevState) => [...prevState, text]);
                    // setQuestionBase64Ans(questionBase64Ans.concat(text))

                    setReviewContent({ ...reviewContent, questionAnswerList: reviewContent.questionAnswerList.concat(text) })

                };

                // Speak the utterance

                speechSynthesis.speak(utterance);

            }

        } catch (e) {
            // Handle any errors that occur
            const data = {
                error_type: "review component",
                error_source: "botQuestion - text to audio",
                error_description: e.toString()
            };
            // // console.log("bot question(catch): ", data);
            // insertErrorData(data);
        }
    };

    /*
    PC_LFS_RC_157 to PC_LFS_RC_207
    startRecording() function is used to record the trainee speech and the show the transcript using mediaRecorderRef and recognitionRef and call the validateAnswerForReview() function 
    params as questionType
    return the recording and the transcript
    */
    const startRecording = async (questionType) => {
        try {
            setLiveTranscript("")
            setStartVisual(true);
            audioChunksRef.current = [];
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            if (questionType === 1) {
                recognitionRef.current.start();
            }


            recognitionRef.current.onresult = (event) => {
                const fullTranscript = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');
                transcriptRef.current = fullTranscript;
                setLiveTranscript(fullTranscript);
            };

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    mediaRecorderRef.current = new MediaRecorder(stream);
                    mediaRecorderRef.current.ondataavailable = (e) => {
                        audioChunksRef.current.push(e.data);
                    };
                    mediaRecorderRef.current.onstop = async () => {

                        setLiveTranscript("")
                        const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                        const base64String = await blobToAudioBase64(blob);

                        // console.log(questionBase64Ans ,"questionBase64Ans");

                        console.log(questionBase64Ans.concat(base64String), "Base64");

                        // setQuestionBase64Ans(questionBase64Ans.concat(base64String));
                        setQuestionBase64Ans((prevState) => [...prevState, base64String]);

                        var endTime = new Date();
                        var timeDiffMilliseconds = endTime - startTime;
                        var timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000); // Convert milliseconds to seconds and round down
                        var minutes = Math.floor(timeDiffSeconds / 60); // Calculate the minutes
                        var seconds = timeDiffSeconds % 60; // Calculate the remaining seconds

                        // Format minutes and seconds with leading zeros if necessary
                        var time = minutes.toString().padStart(2, '0') + '.' + seconds.toString().padStart(2, '0');
                        setLiveTranscript("")

                        let data = {
                            userId: userId,
                            questionKey: reviewContent.questionKey,
                            answerKey: questionType,
                            question: question,
                            reviewQuestionList: reviewContent.reviewQuestionList,
                            attitudeQuestionList: reviewContent.attitudeQuestionList,
                            errorCount: reviewContent.errorCount,
                            questionAnswerList: reviewContent.questionAnswerList,
                            answer: base64String,
                            fileName: reviewContent.fileName,
                            filesize: reviewContent.filesize,
                            fileBlobUrl: reviewContent.fileBlobUrl,
                            fileBase64Data: reviewContent.fileBase64Data,
                            topic: reviewContent.topic
                        };


                        if (questionType == 1) {
                            setInputFieldStatus(false)
                            setReviewContent({ ...reviewContent, answerKey: questionType, answer: base64String });

                            const result2 = await validateAnswerForReview(data);
                            if (result2 == undefined) {
                                let toastData = {
                                    toastType: "Negative",
                                    toastHeaderMessage: "Negative",
                                    toastBodyMessage: "Please Explain in english!"
                                }
                                setToastProp(toastData)
                                setHideToast(false);
                                // routing("/trainee")
                            }
                            else if (result2.status == 200) {
                                var result1 = result2.data
                                // console.log("WSEDRFTGYHUJIKJNHBGFCDXRDBHJNKM-------------------------------------", result1);


                                var message = { "answer": result1["answerResponse"], "time": time };
                                setReviewMessage(reviewMessage.concat(message));
                                setQuestion(result1["nextQuestion"]);
                                setFileBlobUrl(result1["fileBlobUrl"]);

                                // setQuestionAnswerList(questionAnswerList.concat(result1["answer"]))

                                if (result1["responseKey"] === 'T2' || result1["responseKey"] === 'T4') {
                                    // console.log("file file file")
                                    setAudioButton(false)
                                    setInputButton(false)
                                    setFileUpload(true);
                                    setFileUploadBt(true) // Set fileUpload state variable as true
                                }

                                setReviewContent({ ...reviewContent, question: result1["nextQuestion"], fileBlobUrl: result1["fileBlobUrl"], reviewQuestionList: result1["reviewQuestionList"], questionKey: result1["responseKey"], questionAnswerList: result1["questionAnswerList"], errorCount: result1["errorCount"] })

                                setResult(result1)
                                setAudioButton(false)

                            }
                            else {
                                setloading(false)
                                let toastData = {
                                    toastType: "Negative",
                                    toastHeaderMessage: "Negative",
                                    toastBodyMessage: "Something Went Wrong  !"
                                }
                                setToastProp(toastData)
                                setHideToast(false);
                                message = { "answer": "I have noticed some inappropriate behavior in you answer.", "time": time };
                                setQuestion("I have noticed some inappropriate behavior. You must follow the rules strictly and speak only in English.");
                                setReviewMessage(reviewMessage.concat(message));
                                let questAnsList = reviewContent.questionAnswerList
                                setReviewContent({ ...reviewContent, question: "I have noticed some inappropriate behavior. You must follow the rules strictly and speak only in English.", questionAnswerList: questAnsList })
                                setAudioButton(false)
                            }
                        }

                    };


                    mediaRecorderRef.current.start();
                })
                .catch((error) => {
                    setloading(false)
                    let toastData = {
                        toastType: "Negative",
                        toastHeaderMessage: "Negative",
                        toastBodyMessage: "Something Went Wrong  !"
                    }
                    setToastProp(toastData)
                    setHideToast(false);
                    const data = {
                        error_type: "review component",
                        error_source: "start recording",
                        error_description: error
                    };
                    // Send error data to server
                    // insertErrorData(data);
                    console.error('Error accessing microphone:', data);
                });
        } catch (error) {
            setloading(false)
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong  !"
            }
            setToastProp(toastData)
            setHideToast(false);
            const data = {
                error_type: "review component",
                error_source: "start recording",
                error_description: error
            };
            // insertErrorData(data);
            console.error('Error in startRecording:', data);
        }
    };

    /*
    PC_LFS_RC_212 to PC_LFS_RC_221
    stopRecording() function is used to set the mediaRecorderRef and recognitionRef current as stop()
    params as questionType
    return the stop the recording and triggered the onstop in the startRecording() function
    */
    const stopRecording = (questionType) => {
        try {

            // console.log("stop recording function get triggered.")
            setStartVisual(false);
            if (recognitionRef.current) {
                recognitionRef.current.onresult = null;  // Clear onresult listener
                recognitionRef.current.onerror = null;   // Clear onerror listener
                recognitionRef.current.onend = null;     // Clear onend listener

                recognitionRef.current.stop();
                recognitionRef.current.abort();          // Ensure complete termination
                recognitionRef.current = null;
            }

            // Stop MediaRecorder if it's recording and release media stream tracks
            if (mediaRecorderRef.current) {
                if (mediaRecorderRef.current.state === 'recording') {
                    mediaRecorderRef.current.stop();
                }

                const stream = mediaRecorderRef.current.stream;
                if (stream) {
                    stream.getTracks().forEach(track => track.stop());
                    mediaRecorderRef.current.stream = null;
                }

                mediaRecorderRef.current.ondataavailable = null;  // Clear event listener
                mediaRecorderRef.current.onstop = null;           // Clear event listener

                mediaRecorderRef.current = null;
            }

            recognitionRef.current = null;
            mediaRecorderRef.current = null;
        } catch (e) {
            const data = {
                error_type: "review component",
                error_source: "stop recording",
                error_description: e
            };
            // console.log(data)
            // errorApi(data, '/insertErrorData');
        }
    }

    /*
    PC_LFS_RC_267 to PC_LFS_RC_301
    getUploadFile() function is used to get the file which is upload by the trainee and validate the file
    params as event
    return the valid and invalid files
    */
    const getUploadFile = async (event) => {
        try {

            const files = event.target.files;
            setAllFiles(files)
            let validFiles = [];
            let errorFiles = [];
            let fileNameList = [];
            let base64DataList = [];
            let filesizeList = []

            if (fileFlag === true) {
                enterFullScreen();
                setCheatCount(prevCheat => prevCheat - 1);
                setFileFlag(false);
            }

            const allowedTypes = [
                'text/csv',
                'application/json',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/pdf',
                'image/jpeg',
                'image/png',
                'text/html',
                'text/plain',
                'application/vnd.ms-powerpoint',
                'text/sql',
                'application/msword',
                'application/javascript', // JavaScript
                'application/typescript', // TypeScript
                'text/python', // Python
                'application/java-archive', // Java
                'text/x-csrc', // C
                'text/x-c++src', // C++
                'text/x-java-source', // Java
                'text/x-rust', // Rust
                'text/x-swift', // Swift
                'text/x-go', // Go
                'text/x-ruby', // Ruby
                'text/x-php', // PHP
                'text/x-perl', // Perl
                'text/x-haskell', // Haskell
                'text/x-scala', // Scala
                'text/x-erlang', // Erlang
                'text/x-lisp', // Lisp
                'text/x-ocaml', // OCaml
                'text/x-scheme', // Scheme
                'text/x-prolog', // Prolog
                'text/x-csharp', // C#
                'text/x-objectivec', // Objective-C
                'text/x-kotlin', // Kotlin
                'text/x-swift', // Swift
                'text/x-coffeescript', // CoffeeScript
                'text/x-dart', // Dart
                'text/x-fortran', // Fortran
                'text/x-tcl', // Tcl
                'text/x-vhdl', // VHDL
                'text/x-lua', // Lua
            ];

            const allowedExtensions = [
                'csv',
                'json',
                'xlsx',
                'pdf',
                'jpg',
                'jpeg',
                'png',
                'html',
                'txt',
                'ppt',
                'sql',
                'doc',
                'docx',
                'js', // JavaScript
                'jsx', // JSX
                'ts', // TypeScript
                'tsx', // TSX
                'ipynb', // Jupyter Notebook
                'py', // Python
                'java', // Java
                'c', // C
                'cpp', // C++
                'rs', // Rust
                'swift', // Swift
                'go', // Go
                'rb', // Ruby
                'php', // PHP
                'pl', // Perl
                'hs', // Haskell
                'scala', // Scala
                'erl', // Erlang
                'lisp', // Lisp
                'ml', // OCaml
                'scm', // Scheme
                'pl', // Prolog
                'cs', // C#
                'm', // Objective-C
                'kt', // Kotlin
                'swift', // Swift
                'coffee', // CoffeeScript
                'dart', // Dart
                'f90', // Fortran
                'tcl', // Tcl
                'vhd', // VHDL
                'lua', // Lua
                'csv'
            ];


            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
                const fileExtension = file.name.split('.').pop().toLowerCase();

                validFiles.push(file.name)
                // console.log("file name: ", fileSizeMB, fileExtension)

                if (!allowedExtensions.includes(fileExtension)) {
                    errorFiles.push(`Unsupported file type: ${file.name}`);
                }
                if (fileSizeMB > 23) {
                    errorFiles.push(`File size exceeds 1MB limit: ${file.name}`);
                }

            }


            if (errorFiles.length > 0) {
                console.error('Validation Errors:', errorFiles);
                setErrorFiles(true)
                setCurrentFileStatus("All Files: " + validFiles + "\nInvalid Files: " + errorFiles.join(", ") + "\nPlease reupload all the files")
            }
            else {
                // console.log("All Files: " + validFiles + "\nPress enter to validate file")
                setCurrentFileStatus("All Files: " + validFiles + "\nPress enter to validate file")
                setFileStatus(true)
                setFileUpload(false)
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const base64String = await fileToBase64(file);
                    base64DataList.push(base64String["base64Data"]);
                    // console.log(base64String["base64Data"], "base64 stringggggggggggg");
                    fileNameList.push(file.name);
                    filesizeList.push((file.size / 1024).toFixed(1) + "KB")

                }
                // console.log(base64DataList, fileNameList)
                let listOffileName = reviewContent.fileName
                listOffileName.push(fileNameList)

                let listoffilesize = reviewContent.filesize
                listoffilesize.push(filesizeList)
                // console.log("-------------listoffilesize-------------------", listoffilesize);

                // console.log("--------------------------------", listOffileName)
                let listOffileBase64 = reviewContent.fileBase64Data
                listOffileBase64.push(base64DataList)


                // console.log(listOffileBase64, "-----------------------------------------------base64 listtttttttttttttt")
                // setFileBase64Data(fileBase64Data.push(base64DataList));
                // setFileName(fileName.concat(fileNameList));
                // setReviewContent({ ...reviewContent, fileBase64Data: reviewContent.fileBase64Data.push(base64DataList), fileName: reviewContent.fileName.push(fileNameList) })
                setReviewContent(prevState => ({
                    ...prevState,
                    answerKey: 3,
                    fileBase64Data: listOffileBase64,
                    fileName: listOffileName,
                    filesize: listoffilesize
                }));
            }

        } catch (error) {
            const data = {
                error_type: "review component",
                error_source: "getUploadFile",
                error_description: error
            };
            // console.log(data)
            // insertErrorData(data);
        }
    };

    /*
    PC_LFS_RC_264 to PC_LFS_RC_266
    fileUploadButton() function is used to refer the fileInputRef useRef for the file upload
    */
    const fileUploadButton = () => {
        setFileFlag(true);
        setReviewContent((prevState) => ({
            ...prevState,
            answerKey: 3
        }));
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    /*
    PC_LFS_RC_228 to PC_LFS_RC_255
    sendTextorFile() function is used to call the validateAnswerForReview() function based on the forAnswer
    params as forContent
    return the answer and the transcript
    */
    async function sendTextorFile(forContent) {

        // console.log(reviewContent, "errordebuggingHere")

        var endTime = new Date();
        var timeDiffMilliseconds = endTime - startTime;
        var timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000); // Convert milliseconds to seconds and round down
        var minutes = Math.floor(timeDiffSeconds / 60); // Calculate the minutes
        var seconds = timeDiffSeconds % 60; // Calculate the remaining seconds

        // Format minutes and seconds with leading zeros if necessary
        var time = minutes.toString().padStart(2, '0') + '.' + seconds.toString().padStart(2, '0');
        // console.log("------------------------________________-----------------", reviewContent)

        var message = ""

        if (forContent == "textAnswer") {
            setInputFieldStatus(false)
            message = { "answer": reviewContent.answer, "time": time };
            setInputButton(false)
            const result2 = await validateAnswerForReview(reviewContent);
            if (result2.status == 200) {

                var result1 = result2.data
                // console.log(result1, "resultMessageeeeee......");



                setReviewMessage(reviewMessage.concat(message));
                setQuestion(result1["nextQuestion"]);
                setFileBlobUrl(result1["fileBlobUrl"]);

                if (result1["responseKey"] === 'T2' || result1["responseKey"] === 'T4') {
                    // console.log("file file file")
                    setAudioButton(false)
                    setInputButton(false)
                    setFileUpload(true);
                    setFileUploadBt(true)// Set fileUpload state variable as true
                }

                // setQuestionAnswerList(questionAnswerList.concat(result1["answer"]))

                setReviewContent({ ...reviewContent, question: result1["nextQuestion"], fileBlobUrl: result1["fileBlobUrl"], reviewQuestionList: result1["reviewQuestionList"], questionKey: result1["responseKey"], questionAnswerList: result1["questionAnswerList"], errorCount: result1["errorCount"] })

                setResult(result1)
            }
            else {
                setloading(false)
                let toastData = {
                    toastType: "Negative",
                    toastHeaderMessage: "Negative",
                    toastBodyMessage: "Something Went Wrong  !"
                }
                setToastProp(toastData)
                setHideToast(false);
                message = { "answer": "API failed", "time": time };
                setReviewMessage(reviewMessage.concat(message));
                let questAnsList = reviewContent.questionAnswerList
                console.log(questAnsList, "questAnsList");
                questAnsList.pop()
                setReviewContent({ ...reviewContent, questionAnswerList: questAnsList })
            }
        }
        else {

            if (fileStatus) {
                // console.log(fileStatus, "while sending the file")
                setInputFieldStatus(false)
                message = { "answer": "<%sampleFile.text%>", "time": time };
                setFileUploadBt(false)
                setFileStatus(false)
                setCurrentFileStatus("")
                const result2 = await validateAnswerForReview(reviewContent);
                if (result2.status == 200) {
                    var result1 = result2.data
                    // console.log(result1, "resultMessageeeeee......");

                    setReviewMessage(reviewMessage.concat(message));
                    setQuestion(result1["nextQuestion"]);
                    setFileBlobUrl(result1["fileBlobUrl"]);

                    if (result1["responseKey"] === 'T2' || result1["responseKey"] === 'T4') {
                        // console.log("file file file")
                        setAudioButton(false)
                        setInputButton(false)
                        setFileUpload(true);
                        setFileUploadBt(true)// Set fileUpload state variable as true
                    }

                    // setQuestionAnswerList(questionAnswerList.concat(result1["answer"]))

                    setReviewContent({ ...reviewContent, question: result1["nextQuestion"], fileBlobUrl: result1["fileBlobUrl"], reviewQuestionList: result1["reviewQuestionList"], questionKey: result1["responseKey"], questionAnswerList: result1["questionAnswerList"], errorCount: result1["errorCount"] })

                    // console.log(errorFile, "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
                    if (errorFile == true) {
                        console.log("\n\n!@!#!##!#!\n\n\n!@#!#!@!@\n\n")
                        let base64AudioFile = questionBase64Ans;
                        base64AudioFile.pop()
                        console.log(base64AudioFile)
                        // setQuestionBase64Ans((prevState) => prevState.slice(0, -1));
                        // setQuestionBase64Ans(base64AudioFile)
                    }
                    setErrorFiles(false)
                    setResult(result1)

                }
                else {
                    setloading(false)
                    let toastData = {
                        toastType: "Negative",
                        toastHeaderMessage: "Negative",
                        toastBodyMessage: "Something Went Wrong  !"
                    }
                    setToastProp(toastData)
                    setHideToast(false);
                    message = { "answer": "API failed", "time": time };
                    setReviewMessage(reviewMessage.concat(message));
                    let questAnsList = reviewContent.questionAnswerList
                    questAnsList.pop()
                    setReviewContent({ ...reviewContent, questionAnswerList: questAnsList })
                    setErrorFiles(false)
                }
            }
            else {
                // console.log(fileStatus, "file not send to the backend")
                return
            }
        }




    }

    /*
    PC_LFS_RC_91 to PC_LFS_RC_99
    updateReviewMessages() function is used to update the review message answer with the actual file name where the placeholder found
    return the updatedReviewMessages
    */
    const updateReviewMessages = () => {
        const placeholder = "<%sampleFile.text%>";
        let i = 0; // Initialize counter outside the map function
        const updatedReviewMessage = reviewMessage?.map(item => {

            if (item.answer) {
                let answer = item.answer;
                if (answer.includes(placeholder)) {
                    const files = reviewContent.fileName[i].join(', '); // Join the file names with commas
                    answer = answer.replace(placeholder, files); // Replace the placeholder with the file names
                    i = i + 1; // Increment counter only when placeholder is found
                }
                return { ...item, answer };
            }
            else {
                return { ...item }
            }
        });
        // // console.log(updatedReviewMessage)
        setUpdateReviewMessage(updatedReviewMessage);
    }

    /*
    PC_LFS_RC_100 to PC_LFS_RC_109
    bindReviewMessage() function is used to bind the review message value with the html 
    return the transcript along with the html
    */
    const bindReviewMessage = () => {
        // // console.log(updateReviewMessage,"---------------------------------------------------------")
        // updateReviewMessages()
        const filteredMessages = updateReviewMessage.filter((message) => {
            const lowercaseSearchQuery = searchQuery.toLowerCase(); // Convert search query to lowercase

            if (
                (message.question && message.question.toLowerCase().includes(lowercaseSearchQuery)) ||
                (message.answer && message.answer.toLowerCase().includes(lowercaseSearchQuery))
            ) {
                return true; // Include if the question or answer matches the search query (case-insensitive)
            }

            return false; // Exclude if neither question nor answer matches the search query
        });
        console.log(filteredMessages.length, "-filteredMessages.length---------------------------------------------------------------------", questionBase64Ans)
        if (filteredMessages.length === 0 && searchQuery.length == 0) {
            console.log(updateReviewMessage, "log updateReviewMessage");
            return updateReviewMessage?.map((message, index) => {
                if (message.question) {
                    return (
                        <div className="d-flex align-items-start mt-5 column-gap-3" key={index}>
                            <span className="chat-lfs-logo"><img src="images/chat-lfs-logo.svg" alt="Profile-picture" className="profile-img" /></span>
                            <div className="w-100">
                                <p className="mb-0 d-flex justify-content-between align-items-center">
                                    <span className="font-regular font-12 secondary-color">Sid</span>
                                    <span className="py-0 px-1 font-11 font-regular custom-timing-badge custom-badge-violet">{message.time}</span>
                                </p>
                                <p className="mb-0 font-regular font-11 mt-2 primary-color text-start">
                                    {message.question}
                                </p>
                            </div>
                        </div>
                    );
                } else if (message.answer) {
                    return (
                        <div className="d-flex align-items-start mt-5 column-gap-3">
                            <img className="chat-box-img" src="images/sample-profile.svg" alt="Profile-logo" />
                            <div className="w-100">
                                <p className="mb-0 d-flex justify-content-between align-items-center">
                                    <span className="font-regular font-12 secondary-color">{userName}</span>
                                    <span className="py-0 px-1 font-11 font-regular custom-timing-badge custom-badge-violet">{message.time}</span>
                                </p>
                                <p className="mb-0 font-regular font-11 mt-2 primary-color text-start">
                                    {message.answer}
                                </p>
                            </div>
                        </div>
                    );
                }
            });
        }
        else if (filteredMessages.length != 0) {
            return filteredMessages?.map((message, index) => {
                if (message.question) {
                    return (
                        <div className="d-flex align-items-start mt-5 column-gap-3">
                            <span className="chat-lfs-logo"><img src="images/chat-lfs-logo.svg" alt="Profile-picture" className="profile-img" /></span>
                            <div className="w-100">
                                <p className="mb-0 d-flex justify-content-between align-items-center">
                                    <span className="font-regular font-12 secondary-color">Sid</span>
                                    <span className="py-0 px-1 font-11 font-regular custom-timing-badge custom-badge-violet">{message.time}</span>
                                </p>
                                <p className="mb-0 font-regular font-11 mt-2 primary-color text-start">
                                    {message.question}
                                </p>
                            </div>
                        </div>
                    );
                } else if (message.answer) {
                    return (
                        <div className="d-flex align-items-start mt-5 column-gap-3">
                            <img className="chat-box-img" src="images/sample-profile.svg" alt="Profile-logo" />
                            <div className="w-100">
                                <p className="mb-0 d-flex justify-content-between align-items-center">
                                    <span className="font-regular font-12 secondary-color">{userName}</span>
                                    <span className="py-0 px-1 font-11 font-regular custom-timing-badge custom-badge-violet">{message.time}</span>
                                </p>
                                <p class="mb-0 font-regular font-11 mt-2 primary-color text-start">
                                    {message.answer}
                                </p>
                            </div>
                        </div>
                    );
                }
            });
        }
        else {
            return (
                <div class="d-flex align-items-start mt-5 column-gap-3 ">
                    <div class="w-100">
                        <p class="mb-0 font-regular font-11 mt-2 primary-color text-start textCenter">
                            No search result found.
                        </p>
                    </div>
                </div>
            );
        }
    };

    const bindReviewVideo = () => {
        console.log("Binding review video, webcamStream:", webcamStreamrec);
        if (webcamStreamrec instanceof MediaStream) {
            return (
                <LiveStreamVideo
                    stream={webcamStreamrec}
                    className="person-img w-100 h-100" style={{ transform: "scaleX(-1)" }}
                />
            );
        } else {
            console.error("Invalid webcamStream:", webcamStreamrec);
            return <div>Webcam not available</div>;
        }
    };

    const handleButtonClick = () => {
        if (buttonState === 'record') {
            setIsDisabled(true);
            screenRecording();
        } else {
            startTest();
        }
    };

    /*
    PC_LFS_RC_113 to PC_LFS_RC_114
    showInstruction() function is used to show the review instruction 
    return the review instruction and start test button
    */
    function showInstruction() {
        return (
            <>
                <div>

                    {loading ? <Loader /> : (
                        <div>
                            <NavBarComponent></NavBarComponent>
                            <h5 className="font-18 font-semibold p-3 my-lg-3 primary-color text-start">
                                <a href="/trainee" className="me-2">
                                    <img
                                        src="images/back-arrow-white.svg"
                                        alt="back-arrow"
                                        className="custom-arrow-color"
                                    />
                                </a>
                                {training_session_name}
                            </h5>
                            <div className="container-xxxl">

                                <div className="row instruction-container mx-auto p-4">
                                    <div className="col-md-12">
                                        <h5 className="text-center font-22 font-semibold mb-4 mt-2 primary-color">
                                            Review Instruction
                                        </h5>
                                        <ul className="ms-3 font-14 mb-0 ps-0 ps-md-3 black-v2 review-ins-color">
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    Welcome to the Voice-Assisted Test, This test will assess your
                                                    understanding through a series of questions.
                                                </p>
                                            </li>
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    Ensure your microphone is connected and functional.
                                                </p>
                                            </li>
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    Grant necessary permissions for the browser to access your
                                                    microphone.
                                                </p>
                                            </li>
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    Choose a quiet environment to ensure accurate voice recognition.
                                                </p>
                                            </li>
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    You will be asked a series of questions related to the {training_session_name}
                                                </p>
                                            </li>
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    Respond to each question using your voice. Be clear and articulate
                                                    in your answers.
                                                </p>
                                            </li>
                                            <li className='text-start'>
                                                <p className="lh-base">
                                                    Confirm your readiness for the test by saying "Ready" to start.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="text-center mt-4 pt-2">
                                    <button className="btn primary-btn small-device-100 font-semibold font-14" onClick={startTest} disabled={testStarted}>
                                        Start Test
                                    </button>

                                </div> */}
                                <div className="text-center mt-4 pt-2">
                                    <button
                                        className="btn primary-btn small-device-100 font-semibold font-14"
                                        onClick={handleButtonClick}
                                        disabled={isDisabled}
                                    >
                                        {buttonState === 'record' ? 'Start Recording' : 'Start Test'}
                                    </button>
                                </div>

                            </div>

                        </div>
                    )}
                    {hideToast ? <></> :
                        <ToastComponent toastObj={toastProp} />
                    }
                </div>
            </>

        )
    }

    /*
    PC_LFS_RC_117 to PC_LFS_RC_118
    showReviewScreen() function is used to take the review and show the transcript 
    return the review component 
    */
    const showReviewScreen = (webStreamRef) => {
        console.log("called", webStreamRef)
        return (
            <>
                {loading ? <Loader /> : (
                    <div>
                        {showReviewPopup()}

                        <div className="container-xxxl">

                            <div className="row">
                                <div className="col-md-9 common-bg p-0 h-100vh d-flex flex-column ">
                                    <div className="common-bg">
                                        <h4 className="text-center font-18 font-semibold mb-0 mt-4 primary-color">
                                            {training_session_name} Review
                                        </h4>
                                    </div>
                                    <div className='App cust-voice-height'>
                                        <canvas className='full-canvas' ref={canvasRef} hidden={startVisual == false} />
                                    </div>
                                    <div className='textCenter'>
                                        {reviewContent.question}
                                    </div>
                                    <div className="chat-response-container"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-11">
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="w-100 d-flex align-items-center me-3 p-2 textarea-container common-card-theme-bg custom-border">
                                                    {audioButton && !inputButton && (
                                                        <><textarea
                                                            className=" w-100 custom-text-area textarea-box common-card-theme-bg border-0 primary-color"
                                                            placeholder="Enter your questions here"
                                                            defaultValue={liveTranscript} readOnly={true} />
                                                            <button className="border-0 btn cursor-pointer voice-rec-btn" onClick={() => {
                                                                setLiveTranscript("")
                                                                stopRecording(1)
                                                            }} disabled={!inputFieldStatus}>
                                                                <img
                                                                    src="images/voice-record-icon.svg"
                                                                    alt="record-hold-icon" />
                                                            </button></>
                                                    )
                                                    }
                                                    {!audioButton && !fileUploadBt && (
                                                        <><textarea
                                                            ref={textareaRef}
                                                            className="w-100 custom-text-area textarea-box common-card-theme-bg border-0"
                                                            placeholder="Enter your questions here"
                                                            onClick={() => {
                                                                setInputButton(true);
                                                                startRecording(2);
                                                            }}
                                                            onChange={(event) => {
                                                                setReviewContent((prevState) => ({
                                                                    ...prevState,
                                                                    answerKey: 2,
                                                                    answer: event.target.value,
                                                                }));
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event.target.value != 0 && event.key === 'Enter' && !event.shiftKey) {
                                                                    stopRecording(2);
                                                                    event.preventDefault(); // Prevents the default behavior of Enter key (line break)
                                                                    sendTextorFile('textAnswer'); // Call your answerText() function
                                                                    // Clear the textarea value using the ref
                                                                    textareaRef.current.value = '';
                                                                    textareaRef.current.blur();
                                                                }
                                                            }}
                                                            disabled={!inputFieldStatus}
                                                        />
                                                        </>
                                                    )

                                                    }

                                                    {fileUploadBt && (
                                                        <>
                                                            <textarea
                                                                className="w-100 custom-text-area textarea-box common-card-theme-bg border-0"
                                                                placeholder="Enter your questions here"
                                                                defaultValue={currentFileStatus}
                                                                onKeyDown={(event) => {
                                                                    // Disable typing by preventing any input
                                                                    event.preventDefault();
                                                                    // Check if the Enter key is pressed
                                                                    if (event.key === "Enter") {
                                                                        // Call your answerCall function here
                                                                        stopRecording(3)
                                                                        sendTextorFile("fileupload")

                                                                    }
                                                                }}
                                                                // Disable clicking as well
                                                                onClick={(event) => event.preventDefault()}
                                                                readOnly
                                                            />

                                                            <button className="border-0 btn cursor-pointer" onClick={() => {
                                                                fileUploadButton();
                                                                startRecording(3)
                                                            }} disabled={!fileUpload || !inputFieldStatus}>
                                                                <img src="images/link.svg" alt="link-image" className="cursor-pointer" />
                                                                {/* <label htmlFor="fileUpload" className="file-upload border-0 ">
                        
                                                                        <input id="fileUpload" type="file" className="d-none " multiple
                                                                        ref={fileInputRef}
                                                                        onChange={getUploadFile}
                                                                        style={{ display: 'none' }}/>
                                                                    </label> */}
                                                            </button>
                                                            <input
                                                                type="file" multiple
                                                                ref={fileInputRef}
                                                                onChange={getUploadFile}
                                                                style={{ display: 'none' }} // Hide the file input visually
                                                            />
                                                        </>
                                                    )
                                                    }

                                                </div>
                                                {(!audioButton && !inputButton && !fileUploadBt) && <button className="voice-icon custom-border" onClick={() => {
                                                    setAudioButton(true)
                                                    startRecording(1)
                                                }} disabled={!inputFieldStatus}>
                                                    <img
                                                        className="voice-icon-image"
                                                        src="images/voice-icon-gradient.svg"
                                                        alt="voice-icon"
                                                    />
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-xl-3 px-3 common-card-theme-bg custom-border border-bottom-0"> 
                                    <h5 className="mt-4 mb-4 font-medium font-14 primary-color"> 
                                        Transcript
                                    </h5>
                                    <div className="input-group input-search mt-3 me-3"> 
                                        <input
                                            type="search"
                                            className="form-control cust-search-input common-card-theme-bg border-end-0 font-14 font-regular custom-search"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                        <button className="btn border-start-0 custom-search" type="button">
                                            <img src="images/search-icon.svg" alt="search" />
                                        </button>
                                    </div>
                                    <div className="row">
                                        {bindReviewMessage()}
                                    </div>
                                </div> */}
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-12 px-3 common-card-theme-bg custom-border mb-2 h-64 rounded-2 review-scroll">
                                            {/* Changed from col-md-3 to col-lg-4 col-xl-3. Consider if this change is intentional. */}
                                            <h5 className="pt-2 mt-3  me-2 mb-4 font-medium font-14 primary-color">
                                                Transcript
                                            </h5>
                                            <div className="input-group input-search mt-3 me-3">
                                                {/* Added me-3 for margin-end. Ensure this aligns with your design requirements. */}
                                                <input
                                                    type="search"
                                                    className="form-control cust-search-input common-card-theme-bg border-end-0 font-14 font-regular custom-search"
                                                    placeholder="Search"
                                                    value={searchQuery}
                                                    onChange={(event) => setSearchQuery(event.target.value)}
                                                />
                                                <button className="btn border-start-0 custom-search" type="button">
                                                    <img src="images/search-icon.svg" alt="search" />
                                                </button>
                                            </div >
                                            <div className="row chat-container-scroll-2" ref={transcriptContainerRef} 
                                            
                                            >
                                                {bindReviewMessage()}
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-3 common-card-theme-bg custom-border rounded-2">
                                            <h5 className="pt-1 mt-2 font-medium font-12 primary-color mb-2 pb-1">{userName}</h5>
                                            <div className="d-flex align-items-start column-gap-3 position-relative">
                                                <span className="recording-cont">
                                                    <span className="font-9 font-medium color-white"><img src="images/recording-icon.svg" alt="recording-icon" className="me-1" />{formatDuration(duration)}</span>
                                                </span>
                                                {bindReviewVideo()}
                                            </div>


                                        </div>

                                    </div></div>
                            </div>
                        </div>
                    </div>
                )}
                {hideToast ? <></> :
                    <ToastComponent toastObj={toastProp} />
                }
            </>
        )
    }

    /*
    PC_LFS_RC_121 to PC_LFS_RC_122
    showReviewPopup() function is used to show the popup for the test ended
    return the review ended popup message
    */
    const showReviewPopup = () => {
        // // console.log("DTCFBHJNKMRDXFC GBH$#E%RT^&*U(I")
        return (
            <div className={`modal ${reviewPop ? 'show' : ''}`}>
                <div className="modal-dialog kb-logout-popup px-3 py-3">
                    <div className="modal-content common-bg custom-padding">
                        <div className="modal-header border-0 p-0 d-flex align-items-center">
                            <img src="images/review-completed-icon.svg" alt="upload-icon" className="me-3" />
                            <div>
                                <h5 className="modal-title primary-color font-semibold font-22" id="exampleModalLabel">
                                    Review Completed
                                </h5>
                            </div>
                        </div>
                        <div className="modal-body border-0 p-0 ps-md-5 ms-md-4">
                            <p className="font-regular font-18 secondary-color mb-0">Your review session has been completed,
                                based on the review your grade will be shown in training topics.</p>
                        </div>
                        <div className="modal-footer border-0 mt-5 p-0">
                            <button type="button" className="btn primary-btn small-btn font-20 font-semibold m-0"
                                onClick={() => {
                                    setReviewPop(false)
                                    exitFullScreen()
                                    setScreenType(1)
                                    routing("/trainee")
                                    window.location.reload()
                                }}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /*
    PC_LFS_RC_111 to PC_LFS_RC_122
    handleReviewScreen() function is used to handle the hide and show the review screen 
    return the corresponding review screen 
    */
    const handleReviewScreen = () => {
        {
            if (screenType == 1) {
                return showInstruction()
            } else if (screenType == 2) {
                return showReviewScreen()
            } else if (screenType == 3) {
                showReviewPopup()
            }
        }
    }

    return (
        handleReviewScreen()
    )

}

export default ReviewComponent;