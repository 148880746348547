import React, { useEffect, useState } from 'react'
import { deleteFiles } from '../../../Service/AdminApi/knowledgeBaseApi'
import { Modal, Button } from 'react-bootstrap';
import ToastComponent from './toast';
import Loader from './loader';


export const DeleteKb = ({ display, kbId, displayChange, onDeleteSuccess }) => {

  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };
  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);
  // const [loading, setLoading] = useState(false)


  const delFiles = async () => {
    console.log(kbId, "entered");
    // setLoading(true)
    try {
      let reqBody = {
        "kbId": kbId
      };
      console.log(reqBody);
      let result = await deleteFiles(reqBody);
      // { result && setLoading(false) }
      displayChange()
      onDeleteSuccess()

      console.log(result); // do something with the result
    } catch (e) {
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Something Went Wrong !"
      }
      setToastProp(toastData)
      setHideToast(false);
      // console.error(e)
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="delete"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static">
        <div className="modal-dialog delete-popup kb-logout-popup px-3 py-3">
          <div className="modal-content body-bg custom-padding">
            <div className="modal-header border-0 p-0 d-flex align-items-center">
              <img
                src="images/delete-exclamatory-icon.svg"
                alt="upload-icon"
                className="me-3"
              />
              <div>
                <h5
                  className="modal-title primary-color font-semibold font-22 text-start"
                  id="exampleModalLabel"
                >
                  Delete knowledge base
                </h5>
              </div>
            </div>
            <div className="modal-body border-0 p-0 ps-md-5 ms-md-4">
              <p className="font-regular font-18 secondary-color mb-0">
                Are you sure you want to delete this knowledge base ?
              </p>
            </div>
            <div className="modal-footer border-0 mt-5 p-0 m-0 gap-2">
              <button
                type="button"
                className="btn secondary-btn color-white custom-border font-20 font-semibold m-0"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn delete-btn font-20 font-semibold m-0" data-bs-dismiss="modal"
                onClick={() => {
                 
                  delFiles()
                  displayChange()
                  
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp} />
      }
      {/* {loading&&<Loader></Loader>} */}
    </>
  )
}