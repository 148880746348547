import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getClass, getSubBatch, UploadTrainingPLan, getBatch, setEdit, validateUploadfile } from "../../../Service/AdminApi/trainingPlanApi";
import { useState, useEffect } from 'react';
import TrainingGrid from './TrainingGrid';
import ToastComponent from './ToastComponent';
import { jwtDecode } from 'jwt-decode';

const EditTrainingplan = ({ trainingId, setShowEdit, trainingPlansgrid, selectedTrainingData, onEditSuccess, loadingTrue, loadingFalse }) => {
    const [dropdownbatch, setdropdownbatch] = useState([])
    const [dropclass, setdropdownclass] = useState([])
    const [dropdownSubBatch, setDropdownSubBatch] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(selectedTrainingData.BatchName);
    const [selectedfilterClass, setSelectedfilterClass] = useState(selectedTrainingData.Class);
    const [selectedsubBatch, setSelectedSubBatch] = useState(selectedTrainingData.Subgroup);
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
    const [isUploadContainerOpen, setIsUploadContainerOpen] = useState(true);
    const [showError, setShowError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [newBatchId, setNewBatchId] = useState(null);
    const [oldBatchId, setOldBatchId] = useState(null);
    const [newClassId, setNewClassId] = useState(null);
    const [oldClassId, setOldClassId] = useState(null);
    const [newSubBatchId, setNewSubBatchId] = useState(null);
    const [oldSubBatchId, setOldSubBatchId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [batchChanged, setBatchChanged] = useState(false);

    // Toast 
    const toastData = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState(toastData);

    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 15000);
    }, [hideToast]);

    // Pq_te_1.13-Pq_te_1.45
    // this useEffect is used to get the values of class drop down and batch drop down when ther TrainingplanEdit component render 
    useEffect(() => {
        dropdownclass();
        dropbatch();
    }, []);


    // Pq_te_1.35-Pq_te_1.45
    // this function is used to get the batch drop down values and update the dropdownbatch state variable 
    async function dropbatch() {
        try {
            const body = {
                "Batchid": ""
            };
            const result = await getBatch(body);
            setdropdownbatch(result)
        } catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            console.error('Error fetching batch:', error);
        }
    }

    // Pq_te_1.58-Pq_te_1.80
    // this function is used to get the sub batch values based on the selected batch value 
    async function dropSubBatch(selectedBatchId) {
        try {
            const body = {
                Batchid: selectedBatchId
            };
            const result = await getSubBatch(body);
            setDropdownSubBatch(result);
            console.log(result);
        } catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            console.log(error, "ERROR");

        }
    }

    // Pq_te_1.14-Pq_te_1.34
    // this function is used to fetch the values of the class drop down and update the dropclass state variable 
    async function dropdownclass() {
        try {
            const body = {
                data: null
            }
            const result = await getClass(body);
            if (result.data != "No Class found") { setdropdownclass(result.data) }


        }
        catch (error) {
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            console.log(error, "ERROR");
        }
    }

    // Pq_te_1.56
    // when user select the batch value in the batch drop down to get the sub batch value based on the selected  batch value the dropSubBatch() is called and pass the selected batch id 
    const handleBatchChange = (batch) => {
        setSelectedSubBatch("")
        dropSubBatch(batch.batch_id);
    };

    // Pq_te_1.85-Pq_te_1.114
    // check the file type is in xlsx format and call the readFileAsBase64() function and change the file in the Base64 format 
    // and define the body with value as base64,filename and filetype and send to the validateUploadfile(body) function 
    // from the backend it verify whether the xlsx file is the file for TrainingPLan upload 
    // if the response is  The file is not a Training Plan xlsx file the error message is show else the file is shown 
    // check the file type is in xlsx format and call the readFileAsBase64() function and change the file in the Base64 format 
    // and define the body with value as base64,filename and filetype and send to the validateUploadfile(body) function 
    // from the backend it verify whether the xlsx file is the file for TrainingPLan upload 
    // if the response is  The file is not a Training Plan xlsx file the error message is show else the file is shown 
    const handleFileInputChange = async (event) => {
        console.log("file change triggered");
        const file = event.target.files[0];
        if (file) {
            if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.name.endsWith(".xlsx")) {
                const fileData = await readFileAsBase64(file);
                const body = {
                    xlsxfile: fileData.base64Data,
                    filetype: fileData.fileType,
                    fileName: fileData.fileName,
                };
                const result = await validateUploadfile(body);
                if (result.message === 'The file is a Training Plan xlsx file.') {
                    setIsFileUploadOpen(false);
                    setSelectedFile(file);
                    setShowError(false)
                }
                else {
                    let errorMessage = " Please Upload the Valid Template";
                    setShowError(true);
                    setErrorMessage(errorMessage);
                }

            }
            else {
                let errorMessage = "Please Upload the Xlsx file only.";
                setShowError(true);
                setErrorMessage(errorMessage);
            }

        } else {
            console.error("No file selected");
        }
    };


    // Pq_te_1.109-Pq_te_1.111
    // this function is used to show the error message while the user Not upload the Training Plan xlsx 
    const ErrorMessage = ({ errorMessage, showError }) => {
        if (!showError) {
            return null;
        }

        return (
            <span className="mt-2 font-regular font-14 mandatory d-flex align-items-center"><img src="images/error-msg.svg" alt="Error" className="me-2 mb-1" />{errorMessage}</span>
        );
    };

    // Pq_te_1.118-Pq_te_1.173
    // this function is used to edit the Training plan 
    // when user click on save button check if the Batch was selected define the body based on to edit the batch value 
    // if user select the class value define the body based on to edit the class value and call the set edit function 
    // if user select the sub batch define the body based on to edit the sub batch value and call the set edit function 
    // if user select the file change the file to base64 using readFileAsBase64() function and define the body based on to edit the file 
    let jwt = localStorage.getItem('jwt')
    const payload = jwtDecode(jwt);
    const userName = payload.userName;
    const handleSave = async () => {
        try {
            if (selectedBatch && selectedBatch !== selectedTrainingData.BatchName) {
                const body = {
                    newBatchId: newBatchId,
                    oldBatchId: selectedTrainingData.BatchId,
                    created_by: userName,
                    trainingplannerId: selectedTrainingData.trainingId
                }
                //api edit success
                const result = await setEdit(body);
                console.log("batchresult", result);
                { result && loadingFalse() }
                if (result == 200) {
                    onEditSuccess()
                }
                console.log(result);
            }
            if (selectedsubBatch && selectedsubBatch !== selectedTrainingData.Subgroup) {
                const Subbody = {
                    newsubBatchId: newSubBatchId,
                    oldsubBatchId: selectedTrainingData.SubBatchId,
                    trainingplannerId: selectedTrainingData.trainingId,
                    created_by: userName,
                }
                const Subresult = await setEdit(Subbody);
                { Subresult && loadingFalse() }
                if (Subresult == 200) {
                    onEditSuccess()
                }
                console.log(Subresult);
            }
            else if (selectedfilterClass && selectedfilterClass !== selectedTrainingData.Class) {
                if (selectedfilterClass && !selectedFile) {
                    setShowError(true);
                    setErrorMessage("Please Upload the training plan")
                } else {
                    const classBody = {
                        newClassId: newClassId,
                        created_by: userName,
                        oldClassId: selectedTrainingData.ClassId,
                        trainingplannerId: selectedTrainingData.trainingId
                    }
                    const result = await setEdit(classBody);
                    { result && loadingFalse() }
                    console.log('editresult', result);
                    if (result == 200) {
                        onEditSuccess()
                    }
                }

            } else if (selectedFile) {
                const fileData = await readFileAsBase64(selectedFile);
                const body = {
                    fileName: fileData.fileName,
                    Base64file: fileData.base64Data,
                    oldBatchId: selectedTrainingData.BatchId,
                    oldSubBatchId: selectedTrainingData.SubBatchId,
                    oldClassId: selectedTrainingData.ClassId,
                    trainingplannerId: selectedTrainingData.trainingId,
                    created_by: userName,
                };
                console.log(body, "file");

                const result = await setEdit(body);
                { result && loadingFalse() }
                if (result == 200) {
                    onEditSuccess()
                }
                console.log(result);
            }

        } catch (error) {
            console.error("An error occurred:", error);
            let toastData = {
                toastType: "Negative",
                toastHeaderMessage: "Negative",
                toastBodyMessage: "Something Went Wrong !"
            }
            setToastProp(toastData)
            setHideToast(false);
            setShowEdit(false);
        }
    };

    //   Pq_te_1.91-Pq_te_1.102
    //   this function is used to change the file into Base64 format 
    // the selected file is received as params 
    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Define the onload event handler
            reader.onload = () => {
                const base64Data = reader.result.split(",")[1];
                resolve({ base64Data, fileName: file.name, fileType: file.type }); // Include file name and type in the resolved object
            };

            // Define the onerror event handler
            reader.onerror = (error) => {
                reject(error);
            };

            // Read the file as data URL
            reader.readAsDataURL(file);
        });
    };
    // PS_TU_145-PS_TU_152
    // when user click on the download template the xlsx get downloaded 
    const downloadExcelTemplate = () => {
        console.log("triggered");
        const excelTemplateUrl = 'https://avaeuslfdblob.blob.core.windows.net/admin-upload/Training Plan Template.xlsx?sp=r&st=2024-04-30T12:04:12Z&se=2029-08-23T20:04:12Z&sv=2022-11-02&sr=b&sig=1t4L0v9hQrRRoDV%2FLpohfPNfFYpLFvbIBSbq8NwMr6g%3D';
        const link = document.createElement('a');
        link.href = excelTemplateUrl;
        link.setAttribute('download', 'excel-template.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="modal-content body-bg custom-padding">
            <Modal show onHide={() => { setShowEdit(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className='mb-0 d-flex align-items-center'>
                            <span className="upload-icon-style me-3">
                                <img src="images/upload-white-icon.svg" alt="upload-icon" className="custom-arrow-color" /></span>
                            <span className='font-22 font-semibold'>Edit Training Plan</span></h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Class</label>
                            <div className="dropdown topics-dropdown w-100">
                                <span className="action-drop-down ">
                                    <button id="teams"
                                        className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                        <span className="font-medium font-14 primary-color">{selectedfilterClass} </span>
                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                    </button>
                                    <span className="dropdown-menu common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                        {dropclass?.map((value) => (
                                            // Pq_te_1.48-Pq_te_1.51
                                            // when user select the class value in the class drop down selectedfilterClass,newClassId,oldClassId state variable get updated 
                                            <span key={value.class_id} className="d-flex list cursor-pointer p-2 primary-color" onClick={() => {
                                                setSelectedfilterClass(value.class_name);
                                                setNewClassId(value.class_id);
                                                setOldClassId(selectedTrainingData.ClassId);
                                                setIsUploadContainerOpen(true);
                                                setIsUploadContainerOpen(false);
                                                setIsFileUploadOpen(!isFileUploadOpen)
                                            }
                                            }>
                                                {value.class_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Batch name</label>
                            <div className="dropdown topics-dropdown w-100">
                                <span className="action-drop-down ">
                                    <button
                                        className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="true"
                                    >
                                        <span className="font-medium font-14 primary-color">{selectedBatch || 'Select'}</span>
                                        <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                    </button>
                                    <span className="dropdown-menu common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                        {/* sq_Tp_2.6-sq_Tp_2.9
                                    when user select batch value in the batch drop down the selectedBatch,newBatchId,oldBatchId state variable get updated 
                                    and handleBatchChange function get triggered  */}
                                        {dropdownbatch.map((batch) => (
                                            <span
                                                key={batch.batch_id}
                                                className="d-flex list cursor-pointer p-2 primary-color"
                                                onClick={() => {
                                                    setBatchChanged(true)
                                                    setSelectedBatch(batch.batch_name);
                                                    setNewBatchId(batch.batch_id);
                                                    setOldBatchId(selectedTrainingData.BatchId);
                                                    handleBatchChange(batch)
                                                }}
                                            >
                                                {batch.batch_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="row pe-0">
                            <div className="col-md-12 mb-4 pe-0">
                                <label className="font-semibold font-16 mb-2 primary-color">Subgroup</label>
                                <div className="dropdown topics-dropdown w-100">
                                    <span className="action-drop-down ">
                                        <button
                                            className="btn topics-dropdown-btn common-card-theme-bg custom-border  font-regular font-14 custom-form-control w-100 d-flex justify-content-between"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="true" disabled={!batchChanged}
                                        >
                                            <span className="font-medium font-14 primary-color">{selectedsubBatch || "Select"}</span>
                                            <span className="ps-1"><img src="images/down-arrow.svg" alt="dropdown-icon" /></span>
                                        </button>
                                        <span className="dropdown-menu common-card-theme-bg custom-border  font-semibold font-16 w-100 p-3 shadow">
                                            {/* Pq_te_1.81-Pq_te_1.84
                                    when user select the sub batch value selectedsubBatch,newSubBatchId,oldSubBatchId state variable get updated  */}
                                            {dropdownSubBatch.map((subBatch) => (
                                                <span
                                                    key={subBatch.sub_batch_id}
                                                    className="d-flex list cursor-pointer p-2 primary-color"
                                                    onClick={() => {
                                                        setSelectedSubBatch(subBatch.sub_batch_name);
                                                        setNewSubBatchId(subBatch.sub_batch_id);
                                                        setOldSubBatchId(selectedsubBatch);
                                                        // handleSave();
                                                    }}
                                                >
                                                    {subBatch.sub_batch_name}
                                                </span>
                                            ))}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <label className="font-semibold font-16 mb-2 primary-color">Upload</label>
                            {isUploadContainerOpen && (
                                <div className="upload-container mt-3 common-card-theme-bg custom-border">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex gap-4">
                                            <img src="images/folder-icon-white.svg" alt="folder-icon" className="custom-arrow-color" />
                                            <div>
                                                <p className="font-medium font-14 primary-color mb-1">
                                                    {selectedTrainingData.Filename}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="images/popup-close-icon.svg" alt="delete-icon" className="close-icon cursor-pointer" onClick={() => { setIsUploadContainerOpen(false); setIsFileUploadOpen(!isFileUploadOpen) }} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isFileUploadOpen && (
                                <button className="upload-files-container p-4 w-100 border-0 common-bg  mb-2" onChange={handleFileInputChange}>
                                    <label
                                        htmlFor="fileUpload"
                                        className="upload-btn-wrapper cursor-pointer upload-wrapper d-block text-center"
                                    >
                                        <img
                                            src="images/upload-icon-black.svg"
                                            alt="dropfiles"
                                            className="drop-file text-center mb-3"
                                        />
                                        <span className="font-medium font-14 d-block text-center primary-color mb-1">
                                            Upload files from computer
                                        </span>
                                        <span className="font-regular font-12 d-block text-center position-relative secondary-color mb-2">
                                            or drag and drop here
                                        </span>
                                        <div className="file-input-wrapper cursor-pointer">
                                            <label
                                                htmlFor="fileToUpload"
                                                className="btn-upload link-text font-12 font-regular cursor-pointer text-underline"
                                                onClick={downloadExcelTemplate}
                                            >
                                                Download template
                                            </label>
                                        </div>
                                        <input id="fileUpload" type="file" className=" d-none " accept=".xls,.xlsx,application/vnd.ms-excel" />
                                    </label>
                                </button>
                            )}

                            {selectedFile && (
                                <div className="upload-container mt-3 common-card-theme-bg custom-border">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex gap-4">
                                            <img src="images/folder-icon-white.svg" alt="folder-icon" className="custom-arrow-color" />
                                            <div>
                                                <p className="font-medium font-14 primary-color mb-1">
                                                    {selectedFile.name}
                                                </p>
                                                <p className="font-regular font-12 secondary-color mb-0">
                                                    {(selectedFile.size / 1024).toFixed(1) + "KB"}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="images/popup-close-icon.svg" alt="delete-icon" className="close-icon cursor-pointer" onClick={() => { setSelectedFile(null); setIsUploadContainerOpen(true); setShowError(false); }} />
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                        <>



                            <ErrorMessage errorMessage="Invalid file type. Please select a valid Excel file." showError={showError} />
                        </>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4 gap-2">
                            <button type="button" className="btn secondary-btn color-white custom-border font-20 font-semibold m-0" onClick={() => { setSelectedFile(null); setIsUploadContainerOpen(true); setShowError(false); setShowEdit(false) }}>Cancel</button>
                            <button type="button" className="btn primary-btn font-20 font-semibold m-0" onClick={handleSave}>Edit</button>
                        </div>
                        {/* Other components */}
                    </div>

                </Modal.Body>
            </Modal>
        </div >
    );
};

export default EditTrainingplan;
