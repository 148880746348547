import { useEffect, useState, useRef } from "react";
import { getTrainingPlan, deleteTrainingPlan } from "../../../Service/AdminApi/trainingPlanApi";
import EmptyGrid from './emptygrid';
import DeleteTrainingplan from "./deleteTrainingplan";
import EditTrainingplan from "./editTrainingplan"
import Loader from "./loader";
import ToastComponent from "./ToastComponent";


function TrainingGrid({ data, searchValue, filtervalue, onLengthUpdate , loadingFalse,loadingTrue }) {


  const [trainingPlansgrid, setTrainingPlansgrid] = useState([]);
  const [sortfileOrder, setfileSortOrder] = useState('asc');
  const [sortclassOrder, setclassSortOrder] = useState('asc');
  const [sortuploadOrder, setuploadSortOrder] = useState('asc');
  const [uploadTraining, setUploadTraining] = useState(false);
  const [deleteTrainingId, setDeleteTrainingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteid, setdeleteid] = useState(null)
  const [deletesuccess, setdeletesuccess] = useState("")
  const [editsuccess, seteditsuccess] = useState("")
  const [showEdit, setShowEdit] = useState(false);
  const [editTrainingData, seteditTrainingData] = useState({});
  const [sortKey, setSortKey] = useState('training_planner_id')


  // Toast 
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 5000);
  }, [hideToast]);

  // PS_Tp_69-PS_Tp_70
  // check whether the data is true data length is greater than  0 which was send as prop from the TrainingPLan compoent 
  // and if the condition true call the setTrainingPlansgrid() state functon as pass data which update the trainingPlansgrid state variable and the Grid is displayed to the user 
  useEffect(() => {
    try {
      if (data && data.length > 0) {
        // PS_Tp_108-PS_Tp_121
        // if the search value is send as prop from the TrainingPLan component the searchValue gets true and the fetchData function get triggered
        if (searchValue) {
          const fetchData = async () => {
            const body = {
              "fileName": searchValue,
              "batchName": '',
              "subBatchName": '',
              "Classname": '',
              "fromDate": '',
              "toDate": '',
              "sortClassorder": sortclassOrder,
              "sortFileorder": sortfileOrder,
              "sortUploadedorder": sortuploadOrder,
              "sortKey": sortKey
            };
            const result = await getTrainingPlan(body);
            if (result !== "the Upload Training plan") {
              console.log("upload", result);
              setTrainingPlansgrid(result);
              console.log("----------result",result);
              console.log("TrainingplangridVal---------------------------",result.length)
              if(result=="No upload Training Plan found"){
                onLengthUpdate(0);
              }
              else{
                onLengthUpdate(result.length);
              }
              
            }
            else {
              setUploadTraining(true);
              onLengthUpdate(0);
            }
          };
          fetchData();
        }
        //PS_Tp_180-PS_Tp_194
        //when the filter value is send as prop from the TrainingPlan Component the filtervalue get true 
        //and the fetchData get triggered 
        else if (filtervalue) {
        
          const fetchData = async () => {
            const body = {
              "fileName": '',
              "batchName": filtervalue.batchName,
              "subBatchName": filtervalue.subBatchName,
              "Classname": filtervalue.Classname,
              "fromDate": filtervalue.fromDate,
              "toDate": filtervalue.toDate,
              "sortClassorder": sortclassOrder,
              "sortFileorder": sortfileOrder,
              "sortUploadedorder": sortuploadOrder,
              "sortKey": sortKey
            };
            console.log("filterresult", body);
            const result = await getTrainingPlan(body);
           
            if (result !== "the Upload Training plan") {
              console.log("filterfinal", result);
              setTrainingPlansgrid(result);
              if(result=="No upload Training Plan found"){
                onLengthUpdate(0);
              }
              else{
                onLengthUpdate(result.length);
              }
            } else {
              setUploadTraining(true);
            }
          };
          fetchData();
        }
        else {
          setTrainingPlansgrid(data);
        }
      }
    }
    catch (e) {

      let toastData = {
        toastType: 'error',
        toastHeaderMessage: 'Error',
        toastBodyMessage: 'Something Went Wrong'
      }
      setToastProp(toastData)
      setHideToast(false);
    }

  }, [data, searchValue, sortclassOrder, sortfileOrder, sortuploadOrder, filtervalue, deleteTrainingId,editsuccess]);


  useEffect(() => {
    try {
      const fetchData = async () => {
        const body = {
          "fileName": searchValue,
          "batchName": '',
          "subBatchName": '',
          "Classname": '',
          "fromDate": '',
          "toDate": '',
          "sortClassorder": sortclassOrder,
          "sortFileorder": sortfileOrder,
          "sortUploadedorder": sortuploadOrder,
          "sortKey": sortKey
        };
        const result = await getTrainingPlan(body);
        setTrainingPlansgrid(result);
        if(result=="No upload Training Plan found"){
          onLengthUpdate(0);
        }
        else{
          onLengthUpdate(result.length);
        }
      };

      if (sortfileOrder || sortclassOrder || sortuploadOrder) {
        fetchData();
      }
    } catch (e) {
      let toastData = {
        toastType: 'error',
        toastHeaderMessage: 'Error',
        toastBodyMessage: 'Something Went Wrong'
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  }, [sortfileOrder, sortclassOrder, sortuploadOrder, searchValue, deletesuccess, editsuccess]);

  // PS_Tp_211-PS_Tp_218
  // when user click on down arraow in the File column the sortfile function get triggered 
  // if the value is asc it set to desc and if the value is desc it set to asc
  // and the useEffect get triggered 
  function sortfile() {
    setSortKey('file_name')
    console.log("sort oder triggered");
    if (sortfileOrder === "asc") {
      setfileSortOrder("desc")
    }
    else if (sortfileOrder === "desc") {
      setfileSortOrder("asc")
    }
  }

  // PS_Tp_219-PS_Tp_227
  // when user click on down arraow in the Class column the sortclass function get triggered 
  // if the value is asc it set to desc and if the value is desc it set to asc
  // and the useEffect get triggered 
  function sortclass() {
    setSortKey('class_name')
    if (sortclassOrder === "asc") {
      setclassSortOrder("desc")
    }
    else if (sortclassOrder === "desc") {
      setclassSortOrder("asc")
    }
  }

  // PS_Tp_228-PS_Tp_236
  // when user click on down arrow in the Upload column the sortclass function get triggered 
  // if the value is asc it set to desc and if the value is desc it set to asc
  // and the useEffect get triggered
  function sortupload() {
    setSortKey('created_at')
    if (sortuploadOrder === "asc") {
      console.log(sortuploadOrder);
      setuploadSortOrder("desc")
    }
    else if (sortuploadOrder === "desc") {
      setuploadSortOrder("asc")
    }
  }

  // PS_Tp_239-PS_Tp_244
  // when user click on the Delete image on the action column the handleDelete is called 
  // update the showDeleteModal which display the TrainingplanDelete component 
  // and pass the selected TrainingPLanid as prop to the delete component 
  const handleDelete = (trainingId) => {
    setShowDeleteModal(true);
    setdeleteid(trainingId)
  };

  // Pq_tp_1.23-Pq_tp_1.24
  // on successfull deletion of trainingplan the handleDeleteSuccess function is called back from the TrainingPlandelete Component 
  // which update the showDeleteModal and close the delete component and update deletesuccess which render the TrainingGrid Component 
  const handleDeleteSuccess = () => {
    setShowDeleteModal(false);
    setdeletesuccess("success")
    let toastData = {
      toastType: 'Positive',
      toastHeaderMessage: 'Positive',
      toastBodyMessage: 'Successfully deleted the excel !'
    }
    setToastProp(toastData)
    setHideToast(false);
  };

  // Pq_te_1.173-Pq_te_1.175
  // after the successful edit the handleEditSuccess() function is call back from the TrainingplanEdit component 
  // and update the editsuccess state variable and render the TrainingPlanGrid component 
  const handleEditSuccess = () => {
    setShowEdit(false)
    seteditsuccess("success")
    let toastData = {
      toastType: 'Positive',
      toastHeaderMessage: 'Positive',
      toastBodyMessage: 'Successfully Edited the excel !'
    }
    setToastProp(toastData)
    setHideToast(false);
  }
  const noRecordStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '250px', // Adjust height as needed
    margin: '50px'
  };

  const messageStyle = {
    fontSize: '18px',
    color: '#555',
  };

  // PS_Tp_246-PS_Tp_253
  // finds the selected training plan from the trainingPlansgrid array 
  // Destructures the selectedTraining object to extract the values of the BatchId, BatchName, Class, ClassId, SubBatchId, Subgroup, and Filename properties 
  const handleEdit = (trainingId) => {
    const selectedTraining = trainingPlansgrid.find((element) => element.TrainingID === trainingId);

    if (selectedTraining) {
      const { BatchId, BatchName, Class, ClassId, SubBatchId, Subgroup, Filename } = selectedTraining;

      seteditTrainingData({
        ...editTrainingData,
        trainingId,
        BatchId,
        BatchName,
        Class,
        ClassId,
        SubBatchId,
        Subgroup,
        Filename
      });

      setShowEdit(true); // Show the TrainingplanEdit component
    }
  };

  // PS_Tp_82-PS_Tp_90
  // this function is used to achieve the date format to be displayed as in the visuals 
  // and this is achieved by defining the month to be displayed as per the visuals in the month list and using the methods of Date() instance 
  function formatDate(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }


  return (
    <>
   
      <div className="table-responsive theme-table bg-white">
        <div>
        {uploadTraining ? (
          <EmptyGrid message="No training plan base uploaded! No knowledge base found at the moment." />
        ) :
          (
           
            Array.isArray(trainingPlansgrid) && trainingPlansgrid.length > 0 ? (
              <table className="table custom-table mb-0 align-middle text-nowrap">
                <thead>
                  <tr>
                    <th className="text-nowrap py-3 px-3 px-lg-4">
                      File name
                      <span className="ms-1 cursor-pointer">
                        <img
                          src="images/down-arrow-white.svg"
                          alt="sort-arrow-down"
                          className="custom-arrow-color" onClick={sortfile}
                        />
                      </span>
                    </th>
                    <th className="text-nowrap py-3 px-3 px-lg-4">
                      Class
                      <span className="ms-1 cursor-pointer">
                        <img
                          src="images/down-arrow-white.svg"
                          alt="sort-arrow-down"
                          className="custom-arrow-color"
                          onClick={sortclass}
                        />
                      </span>
                    </th>
                    <th className="text-nowrap py-3 px-3 px-lg-4 text-center">
                      Batch Name
                    </th>
                    <th className="text-nowrap py-3 px-3 px-lg-4 text-center">Subgroup</th>
                    <th className="text-nowrap py-3 px-3 px-lg-4">
                      Uploaded on
                      <span className="ms-1 cursor-pointer">
                        <img
                          src="images/down-arrow-white.svg"
                          alt="sort-arrow-down"
                          className="custom-arrow-color"
                          onClick={sortupload}
                        />
                      </span>
                    </th>
                    <th className="text-nowrap text-center py-3 px-3 px-lg-4">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* PS_Tp_76-PS_Tp_92
                  this function is used the map the values from the TrainingPlan state variable 
                  the date column data is formated using the formt data function 
                  check the trainingPlansgrid length is greater than 0 and mapp the trainingPlansgrid values */}
                  {
                    trainingPlansgrid.length > 0 && trainingPlansgrid.map((values, index) => (
                      <tr>
                        <td className="py-3 px-3 px-lg-4 primary-color">{values.Filename}</td>
                        <td class="py-3 px-3 px-lg-4 secondary-color">{values.Class}</td>
                        <td className="py-3 px-3 px-lg-4 text-center">
                          <span className="custom-badge custom-badge-violet">{values.BatchName}</span>
                        </td>
                        <td className="py-3 px-3 px-lg-4 text-center">
                          {values.Subgroup ? (
                            <span className="custom-badge custom-badge-green">{values.Subgroup}</span>
                          ) : (
                            <span>{values.Subgroup}</span>
                          )}
                        </td>
                        <td className="py-3 px-3 px-lg-4 secondary-color">{formatDate(values.Uploadedon)}</td>
                        <td className="py-3 px-3 px-lg-4">
                          <span className="d-flex justify-content-center">
                            <img
                              className="me-2 cursor-pointer"
                              src="images/table-edit-icon.svg"
                              alt="table-edit-icon"
                              title="Edit"
                              onClick={() => handleEdit(values.TrainingID)} // Call handleEdit on edit button click
                            />

                            <div>
                              <>
                                {/* Your component logic here */}
                                <img
                                  className="ms-2 cursor-pointer"
                                  src="images/table-delete-icon.svg"
                                  alt="table-delete-icon"
                                  title="Delete"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#delete"
                                  data-trainingid={values.TrainingID}
                                  onClick={() => handleDelete(values.TrainingID, values.BatchName, values.Subgroup)}
                                />
                              </>
                            </div>
                          </span>
                        </td>
                      </tr>
                    ))
                  }
                  {showDeleteModal && <DeleteTrainingplan
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    trainingId={deleteid}
                    onCancel={() => {
                      setShowDeleteModal(false); // Hide the component when cancel is clicked
                      setdeleteid(null); // Reset the deleteid state
                    }}
                    onDeleteSuccess={handleDeleteSuccess}
                  />}
                  {showEdit && <EditTrainingplan selectedTrainingData={editTrainingData} setShowEdit={setShowEdit} trainingPlansgrid={trainingPlansgrid} onEditSuccess={handleEditSuccess} loadingFalse={loadingFalse} loadingTrue={loadingTrue} />}              </tbody>
              </table>
            ) : (
              // <EmptyGrid message="No training plan base uploaded! No knowledge base found at the moment." />
              <div className="table-responsive theme-table bg-white font-semibold">
                <table className="table custom-table mb-0 align-middle text-nowrap">
                  <thead className="common-card-theme-bg ">
                    <tr className="common-card-theme-bg ">
                      <th className="text-nowrap py-3 px-3 px-lg-4 ">
                        File name
                        <span className="ms-1 cursor-pointer">
                          <img
                            src="images/down-arrow-white.svg"
                            alt="sort-arrow-down"
                            className="custom-arrow-color"
                          />
                        </span>
                      </th>
                      <th className="text-nowrap py-3 px-3 px-lg-4">
                        Class
                        <span className="ms-1 cursor-pointer">
                          <img
                            src="images/down-arrow-white.svg"
                            alt="sort-arrow-down"
                            className="custom-arrow-color"
                          />
                        </span>
                      </th>
                      <th className="text-nowrap py-3 px-3 px-lg-4">
                        Uploaded on
                        <span className="ms-1 cursor-pointer">
                          <img
                            src="images/down-arrow-white.svg"
                            alt="sort-arrow-down"
                            className="custom-arrow-color"
                          />
                        </span>
                      </th>
                      <th className="text-nowrap text-center py-3 px-3 px-lg-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={4} className="primary-color">
                        <div className="p-5 text-center">
                          <img
                            src="images/search-icon-lfs.svg"
                            alt="three-lines-icon"
                            className="pb-4"
                          />
                          <p className="font-14 font-medium primary-color mb-1">
                            No search results found !
                          </p>
                          <p className="font-12 font-medium secondary-color mb-2">
                            It seems we couldn't find any matches for your search
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          )}
          </div>
      
      </div>
      {hideToast ? <></> :
        <ToastComponent toastObj={toastProp} />
      }
    
    </>

  );
}

export default TrainingGrid;