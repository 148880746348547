import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteTrainingPlan } from "../../../Service/AdminApi/trainingPlanApi";
import ToastComponent from '../Overview/ToastComponent';
import { jwtDecode } from 'jwt-decode';


const DeleteTrainingplan = ({ show, onHide, trainingId, onCancel, onDeleteSuccess }) => {
  // Pq_tp_1.7-Pq_tp_1.23
  // this function is used the delete the Training plan and if the 
  // result is Successfully deleted the excel the onDeleteSuccess() function is called back which update the deletesuccess state variable in the TrainingGrid Component and render the TrainingGrid COmponent 
  // Toast 
  const toastData = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState(toastData);

  let jwt = localStorage.getItem('jwt')
  const payload = jwtDecode(jwt);
  const userName = payload.userName;

  /**PS_AG_15
    * This function is used to show and hide the toast message
    * hideToast state variable is passed as the dependency
    */
  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 5000);
  }, [hideToast]);

  const handleDelete = async () => {
    try {

      const body = {
        trainingplannerid: trainingId,
        created_by : userName
      };
      //Api delete success
      const result = await deleteTrainingPlan(body);

      console.log("result", result);
      if (result === 'Successfully deleted the excel') {
        onDeleteSuccess(); // Notify parent component of successful deletion
        let toastData = {
          toastType: 'Positive',
          toastHeaderMessage: 'Positive',
          toastBodyMessage: 'Training Plan deleted sucessfully !'
        }
        setToastProp(toastData)
        setHideToast(false);
      }

    } catch (error) {
      // setLoading(false)
      let toastData = {
        toastType: "Negative",
        toastHeaderMessage: "Negative",
        toastBodyMessage: "Deletion Failed !"
      }
      setToastProp(toastData)
      setHideToast(false);
    }
  };

  // Pq_tp_1.27-Pq_tp_1.30
  // when user click on the cancel button the onHide function is used to hide the TrainingDelete Component 
  // and the onCancel is used to set the state variable to its default value 
  const handleCancel = () => {
    onHide();
    onCancel();
  };

  return (
    // <div class="modal fade" id="delete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    //   data-bs-backdrop="static">
    //   <div class="modal-dialog kb-logout-popup px-3 py-3">
    //     <div class="modal-content body-bg custom-padding">
    //       <Modal show={show} onHide={onHide} >
    //         <Modal.Header closeButton>
    //           <Modal.Title>Delete Training Plan</Modal.Title>
    //         </Modal.Header>

    //         <Modal.Body>
    //           <div className="d-flex align-items-center">
    //             <img src="images/delete-exclamatory-icon.svg" alt="upload-icon" className="me-3" />
    //             <div>
    //               <p>Are you sure you want to delete this Training Plan?</p>
    //             </div>
    //           </div>
    //         </Modal.Body>
    //         <Modal.Footer>
    //           <Button variant="secondary" onClick={handleCancel}>
    //             Cancel
    //           </Button>
    //           <Button variant="danger" onClick={()=>{handleDelete();{onDeleteSuccess && onHide()}}}>
    //             Delete
    //           </Button>
    //         </Modal.Footer>
    //       </Modal>
    //     </div>
    //   </div>
    //   {hideToast ? <></> :
    //     <ToastComponent name={toastProp} />
    //   }
    // </div>
    <div className={`modal fade ${show ? 'show' : ''}`} tabIndex={-1} aria-hidden={!show} style={{ display: show ? 'block' : 'none' }}
      id="delete"
      // tabIndex={-1}
      // aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
      // data-bs-backdrop="static"
      // hidden={!show}
      >
      <div className="modal-dialog delete-popup kb-logout-popup p-0 rounded-3">
        <div className="modal-content body-bg custom-padding">
          <div className="modal-header border-0 p-0 d-flex align-items-center">
            <img
              src="images/delete-exclamatory-icon.svg"
              alt="upload-icon"
              className="me-3"
            />
            <div>
              <h5
                className="modal-title primary-color font-semibold font-22 text-start"
                id="exampleModalLabel"
              >
                Delete Training Plan
              </h5>
            </div>
          </div>
          <div className="modal-body border-0 p-0 ps-md-5 ms-md-4">
            <p className="font-regular font-18 secondary-color mb-0">
              Are you sure you want to delete this Training Plan ?
            </p>
          </div>
          <div className="modal-footer border-0 mt-5 p-0 m-0 gap-2">
            <button
              type="button"
              className="btn secondary-btn color-white custom-border font-20 font-semibold m-0"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn delete-btn font-20 font-semibold m-0" data-bs-dismiss="modal"
              onClick={() => { handleDelete(); { onDeleteSuccess && onHide() } }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      {hideToast ? <></> :
        <ToastComponent name={toastProp} />
      }
    </div>

  );
};

export default DeleteTrainingplan;
